@import "https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,800;1,300;1,400;1,500&display=swap";
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-weight: 500;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto;
}

a {
  transition: 0.2s ease-in-out color;
}

.hr-thin {
  color: var(--bs-border-color-thin);
}

img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

svg {
  max-width: 100%;
}

iframe {
  width: 100%;
}

.img-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

::-moz-selection {
  background: rgba(5, 143, 255, 0.2);
}

::selection {
  background: rgba(5, 143, 255, 0.2);
}

:root,
[data-bs-theme="light"] {
  --bs-blue: #058fff;
  --bs-tory-blue: #00406e;
  --bs-indigo: #6610f2;
  --bs-purple: #6a6fea;
  --bs-pink: #d63384;
  --bs-red: #f05564;
  --bs-orange: #ff8800;
  --bs-yellow: #ffcc18;
  --bs-green: #42d78c;
  --bs-teal: #20c997;
  --bs-cyan: #16cef3;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-gray: #48484d;
  --bs-gray-dark: #252529;
  --bs-gray-100: #f6f6f7;
  --bs-gray-200: #e7e7e8;
  --bs-gray-300: #d5d5d8;
  --bs-gray-400: #c0c0c2;
  --bs-gray-500: #8e8e93;
  --bs-gray-600: #48484d;
  --bs-gray-700: #313136;
  --bs-gray-800: #252529;
  --bs-gray-900: #1e1e20;
  --bs-primary: #ff8800;
  --bs-accent: #00406e;
  --bs-secondary: #8e8e93;
  --bs-success: #42d78c;
  --bs-danger: #f05564;
  --bs-warning: #ffcc18;
  --bs-info: #058fff;
  --bs-light: #f6f6f7;
  --bs-dark: #252529;
  --bs-primary-rgb: 255, 136, 0;
  --bs-accent-rgb: 51, 85, 153;
  --bs-secondary-rgb: 142, 142, 147;
  --bs-success-rgb: 66, 215, 140;
  --bs-danger-rgb: 240, 85, 100;
  --bs-warning-rgb: 255, 204, 24;
  --bs-info-rgb: 5, 143, 255;
  --bs-light-rgb: 246, 246, 247;
  --bs-dark-rgb: 37, 37, 41;
  --bs-primary-text-emphasis: #663600;
  --bs-secondary-text-emphasis: #39393b;
  --bs-success-text-emphasis: #1a5638;
  --bs-info-text-emphasis: #023966;
  --bs-warning-text-emphasis: #66520a;
  --bs-danger-text-emphasis: #602228;
  --bs-light-text-emphasis: #313136;
  --bs-dark-text-emphasis: #313136;
  --bs-primary-bg-subtle: #ffe7cc;
  --bs-secondary-bg-subtle: #e8e8e9;
  --bs-success-bg-subtle: #d9f7e8;
  --bs-info-bg-subtle: #cde9ff;
  --bs-warning-bg-subtle: #fff5d1;
  --bs-danger-bg-subtle: #fcdde0;
  --bs-light-bg-subtle: #fbfbfb;
  --bs-dark-bg-subtle: #c0c0c2;
  --bs-primary-border-subtle: #ffcf99;
  --bs-secondary-border-subtle: #d2d2d4;
  --bs-success-border-subtle: #b3efd1;
  --bs-info-border-subtle: #9bd2ff;
  --bs-warning-border-subtle: #ffeba3;
  --bs-danger-border-subtle: #f9bbc1;
  --bs-light-border-subtle: #e7e7e8;
  --bs-dark-border-subtle: #8e8e93;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Rubik", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #1e1e20;
  --bs-body-color-rgb: 30, 30, 32;
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(30, 30, 32, 0.75);
  --bs-secondary-color-rgb: 30, 30, 32;
  --bs-secondary-bg: #e7e7e8;
  --bs-secondary-bg-rgb: 231, 231, 232;
  --bs-tertiary-color: rgba(30, 30, 32, 0.5);
  --bs-tertiary-color-rgb: 30, 30, 32;
  --bs-tertiary-bg: #f6f6f7;
  --bs-tertiary-bg-rgb: 246, 246, 247;
  --bs-heading-color: #1e1e20;
  --bs-link-color: #ff8800;
  --bs-link-color-rgb: 255, 136, 0;
  --bs-link-decoration: none;
  --bs-link-hover-color: #d97400;
  --bs-link-hover-color-rgb: 217, 116, 0;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff5d1;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #d5d5d8;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.125);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 0.75rem;
  --bs-border-radius-xxl: 1.5rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
    0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 4px 16px 0 rgba(0, 0, 0, 0.06);
  --bs-box-shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.08),
    0 2px 6px 0 rgba(0, 0, 0, 0.06);
  --bs-box-shadow-lg: 0 1px 4px 0 rgba(0, 0, 0, 0.12),
    0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  --bs-box-shadow-inset: inset 0 1px 2px transparent;
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(255, 136, 0, 0.25);
  --bs-form-valid-color: #42d78c;
  --bs-form-valid-border-color: #42d78c;
  --bs-form-invalid-color: #f05564;
  --bs-form-invalid-border-color: #f05564;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #d5d5d8;
  --bs-body-color-rgb: 213, 213, 216;
  --bs-body-bg: #1e1e20;
  --bs-body-bg-rgb: 30, 30, 32;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(213, 213, 216, 0.75);
  --bs-secondary-color-rgb: 213, 213, 216;
  --bs-secondary-bg: #252529;
  --bs-secondary-bg-rgb: 37, 37, 41;
  --bs-tertiary-color: rgba(213, 213, 216, 0.5);
  --bs-tertiary-color-rgb: 213, 213, 216;
  --bs-tertiary-bg: #222225;
  --bs-tertiary-bg-rgb: 34, 34, 37;
  --bs-primary-text-emphasis: #ffb866;
  --bs-secondary-text-emphasis: #bbbbbe;
  --bs-success-text-emphasis: #8ee7ba;
  --bs-info-text-emphasis: #69bcff;
  --bs-warning-text-emphasis: #ffe074;
  --bs-danger-text-emphasis: #f699a2;
  --bs-light-text-emphasis: #f6f6f7;
  --bs-dark-text-emphasis: #d5d5d8;
  --bs-primary-bg-subtle: #331b00;
  --bs-secondary-bg-subtle: #1c1c1d;
  --bs-success-bg-subtle: #0d2b1c;
  --bs-info-bg-subtle: #011d33;
  --bs-warning-bg-subtle: #332905;
  --bs-danger-bg-subtle: #301114;
  --bs-light-bg-subtle: #252529;
  --bs-dark-bg-subtle: #131315;
  --bs-primary-border-subtle: #995200;
  --bs-secondary-border-subtle: #555558;
  --bs-success-border-subtle: #288154;
  --bs-info-border-subtle: #035699;
  --bs-warning-border-subtle: #997a0e;
  --bs-danger-border-subtle: #90333c;
  --bs-light-border-subtle: #313136;
  --bs-dark-border-subtle: #252529;
  --bs-heading-color: #f6f6f7;
  --bs-link-color: #ffb866;
  --bs-link-hover-color: #ffc37d;
  --bs-link-color-rgb: 255, 184, 102;
  --bs-link-hover-color-rgb: 255, 195, 125;
  --bs-code-color: #e685b5;
  --bs-border-color: #313136;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.075);
  --bs-form-valid-color: #8ee7ba;
  --bs-form-valid-border-color: #8ee7ba;
  --bs-form-invalid-color: #f699a2;
  --bs-form-invalid-border-color: #f699a2;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {

  h3,
  .h3 {
    font-size: 2em;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.8em;
}

mark,
.mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.8em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.8em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.8em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.display-404 {
  font-size: calc(2.325rem + 12.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-404 {
    font-size: 12rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.8em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.8em;
  color: #48484d;
}

.blockquote-footer::before {
  content: "â€”Â ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875rem;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1260px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.25rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.25rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 1.75rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 1.75rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.25rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.25rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 2.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 2.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 2.5rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 2rem;
  }

  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 2rem;
  }

  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 2.5rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 2.5rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 2.5rem;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state,
      var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: var(--bs-border-width) 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 var(--bs-border-width);
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-borderless> :not(:first-child) {
  border-top-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns> :not(caption)>tr> :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover>tbody>tr:hover>* {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000000;
  --bs-table-bg: #ffe7cc;
  --bs-table-border-color: #e6d0b8;
  --bs-table-striped-bg: #f2dbc2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6d0b8;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ecd6bd;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000000;
  --bs-table-bg: #e8e8e9;
  --bs-table-border-color: #d1d1d2;
  --bs-table-striped-bg: #dcdcdd;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #d1d1d2;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #d7d7d8;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000000;
  --bs-table-bg: #d9f7e8;
  --bs-table-border-color: #c3ded1;
  --bs-table-striped-bg: #ceebdc;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #c3ded1;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c9e4d7;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000000;
  --bs-table-bg: #cde9ff;
  --bs-table-border-color: #b9d2e6;
  --bs-table-striped-bg: #c3ddf2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #b9d2e6;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #bed8ec;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000000;
  --bs-table-bg: #fff5d1;
  --bs-table-border-color: #e6ddbc;
  --bs-table-striped-bg: #f2e9c7;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6ddbc;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ece3c1;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000000;
  --bs-table-bg: #fcdde0;
  --bs-table-border-color: #e3c7ca;
  --bs-table-striped-bg: #efd2d5;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e3c7ca;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e9cccf;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000000;
  --bs-table-bg: #f6f6f7;
  --bs-table-border-color: #ddddde;
  --bs-table-striped-bg: #eaeaeb;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #ddddde;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e4e4e4;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #252529;
  --bs-table-border-color: #3b3b3e;
  --bs-table-striped-bg: #303034;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #3b3b3e;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #353539;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.625rem + var(--bs-border-width));
  padding-bottom: calc(0.625rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.75rem + var(--bs-border-width));
  padding-bottom: calc(0.75rem + var(--bs-border-width));
  font-size: 1.0625rem;
}

.col-form-label-sm {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.8em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.625rem 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #ffc480;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 136, 0, 0.25);
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::-moz-placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::-webkit-file-upload-button {
  padding: 0.625rem 1rem;
  margin: -0.625rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  -webkit-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.form-control::file-selector-button {
  padding: 0.625rem 1rem;
  margin: -0.625rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--bs-secondary-bg);
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.625rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: rgba(0, 0, 0, 0);
  border: solid rgba(0, 0, 0, 0);
  border-width: var(--bs-border-width) 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-sm::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.75rem 1.25rem;
  font-size: 1.0625rem;
  border-radius: var(--bs-border-radius-lg);
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.75rem 1.25rem;
  margin: -0.75rem -1.25rem;
  -webkit-margin-end: 1.25rem;
  margin-inline-end: 1.25rem;
}

.form-control-lg::file-selector-button {
  padding: 0.75rem 1.25rem;
  margin: -0.75rem -1.25rem;
  -webkit-margin-end: 1.25rem;
  margin-inline-end: 1.25rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.25rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1.5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.25rem + calc(var(--bs-border-width) * 2));
  padding: 0.625rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color.form-control-sm {
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1.5rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23252529' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.625rem 3rem 0.625rem 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-secondary-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #ffc480;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 136, 0, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 0 var(--bs-secondary-color);
}

.form-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  font-size: 1.0625rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23d5d5d8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #ffc480;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 136, 0, 0.25);
}

.form-check-input:checked {
  background-color: #f80;
  border-color: #f80;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #f80;
  border-color: #f80;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.375em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 1.875;
  margin-left: -2.375em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 1.875;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffc480'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.375em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.375em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn,
.btn-check:disabled+.btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow:
    0 0 0 1px #fff,
    0 0 0 0.25rem rgba(255, 136, 0, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow:
    0 0 0 1px #fff,
    0 0 0 0.25rem rgba(255, 136, 0, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: #f80;
  border: 0;
  border-radius: 1rem;
  -webkit-transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #ffdbb3;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  -moz-appearance: none;
  appearance: none;
  background-color: #f80;
  border: 0;
  border-radius: 1rem;
  -moz-transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #ffdbb3;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid rgba(0, 0, 0, 0);
  transform-origin: 0 0;
  transition:
    opacity 0.1s ease-in-out,
    transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  padding: 1rem 1rem;
}

.form-floating>.form-control::-moz-placeholder,
.form-floating>.form-control-plaintext::-moz-placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating>.form-control::placeholder,
.form-floating>.form-control-plaintext::placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating>.form-control:not(:-moz-placeholder-shown),
.form-floating>.form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown),
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill,
.form-floating>.form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label::after {
  position: absolute;
  inset: 1rem 0.5rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
  position: absolute;
  inset: 1rem 0.5rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}

.form-floating>.form-control:-webkit-autofill~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control-plaintext~label {
  border-width: var(--bs-border-width) 0;
}

.form-floating> :disabled~label,
.form-floating>.form-control:disabled~label {
  color: #48484d;
}

.form-floating> :disabled~label::after,
.form-floating>.form-control:disabled~label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-select,
.input-group>.form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus,
.input-group>.form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.625rem 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  padding: 0.75rem 1.25rem;
  font-size: 1.0625rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation>.form-floating:nth-last-child(n + 3)>.form-control,
.input-group.has-validation>.form-floating:nth-last-child(n + 3)>.form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.form-floating:not(:first-child)>.form-control,
.input-group>.form-floating:not(:first-child)>.form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 1.25rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2342d78c' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3125rem) center;
  background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.25rem);
  background-position: top calc(0.375em + 0.3125rem) right calc(0.375em + 0.3125rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2342d78c' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 5.5rem;
  background-position:
    right 1rem center,
    center right 3rem;
  background-size:
    16px 12px,
    calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.25rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group>.form-control:not(:focus):valid,
.input-group>.form-control:not(:focus).is-valid,
.was-validated .input-group>.form-select:not(:focus):valid,
.input-group>.form-select:not(:focus).is-valid,
.was-validated .input-group>.form-floating:not(:focus-within):valid,
.input-group>.form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 1.25rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f05564'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f05564' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3125rem) center;
  background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.25rem);
  background-position: top calc(0.375em + 0.3125rem) right calc(0.375em + 0.3125rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f05564'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f05564' stroke='none'/%3e%3c/svg%3e");
  padding-right: 5.5rem;
  background-position:
    right 1rem center,
    center right 3rem;
  background-size:
    16px 12px,
    calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.25rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group>.form-control:not(:focus):invalid,
.input-group>.form-control:not(:focus).is-invalid,
.was-validated .input-group>.form-select:not(:focus):invalid,
.input-group>.form-select:not(:focus).is-invalid,
.was-validated .input-group>.form-floating:not(:focus-within):invalid,
.input-group>.form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-padding-y: 0.625rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.9375rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check+.btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible+.btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked+.btn:focus-visible,
:not(.btn-check)+.btn:active:focus-visible,
.btn:first-child:active:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ff8800;
  --bs-btn-border-color: #ff8800;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ff9a26;
  --bs-btn-hover-border-color: #ff941a;
  --bs-btn-focus-shadow-rgb: 217, 116, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffa033;
  --bs-btn-active-border-color: #ff941a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ff8800;
  --bs-btn-disabled-border-color: #ff8800;
}

.btn-accent {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #00406e;
  --bs-btn-border-color: #00406e;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2b4882;
  --bs-btn-hover-border-color: #29447a;
  --bs-btn-focus-shadow-rgb: 82, 111, 168;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #29447a;
  --bs-btn-active-border-color: #264073;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #00406e;
  --bs-btn-disabled-border-color: #00406e;
}

.btn-secondary {
  --bs-btn-color: #000000;
  --bs-btn-bg: #8e8e93;
  --bs-btn-border-color: #8e8e93;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #9f9fa3;
  --bs-btn-hover-border-color: #99999e;
  --bs-btn-focus-shadow-rgb: 121, 121, 125;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #a5a5a9;
  --bs-btn-active-border-color: #99999e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #8e8e93;
  --bs-btn-disabled-border-color: #8e8e93;
}

.btn-success {
  --bs-btn-color: #000000;
  --bs-btn-bg: #42d78c;
  --bs-btn-border-color: #42d78c;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #5edd9d;
  --bs-btn-hover-border-color: #55db98;
  --bs-btn-focus-shadow-rgb: 56, 183, 119;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #68dfa3;
  --bs-btn-active-border-color: #55db98;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #42d78c;
  --bs-btn-disabled-border-color: #42d78c;
}

.btn-danger {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f05564;
  --bs-btn-border-color: #f05564;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f26f7b;
  --bs-btn-hover-border-color: #f26674;
  --bs-btn-focus-shadow-rgb: 204, 72, 85;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f37783;
  --bs-btn-active-border-color: #f26674;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f05564;
  --bs-btn-disabled-border-color: #f05564;
}

.btn-warning {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffcc18;
  --bs-btn-border-color: #ffcc18;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffd43b;
  --bs-btn-hover-border-color: #ffd12f;
  --bs-btn-focus-shadow-rgb: 217, 173, 20;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffd646;
  --bs-btn-active-border-color: #ffd12f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffcc18;
  --bs-btn-disabled-border-color: #ffcc18;
}

.btn-info {
  --bs-btn-color: #000000;
  --bs-btn-bg: #058fff;
  --bs-btn-border-color: #058fff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #2ba0ff;
  --bs-btn-hover-border-color: #1e9aff;
  --bs-btn-focus-shadow-rgb: 4, 122, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #37a5ff;
  --bs-btn-active-border-color: #1e9aff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #058fff;
  --bs-btn-disabled-border-color: #058fff;
}

.btn-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f6f6f7;
  --bs-btn-border-color: #f6f6f7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d1d1d2;
  --bs-btn-hover-border-color: #c5c5c6;
  --bs-btn-focus-shadow-rgb: 209, 209, 210;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c5c5c6;
  --bs-btn-active-border-color: #b9b9b9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f6f6f7;
  --bs-btn-disabled-border-color: #f6f6f7;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #252529;
  --bs-btn-border-color: #252529;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #464649;
  --bs-btn-hover-border-color: #3b3b3e;
  --bs-btn-focus-shadow-rgb: 70, 70, 73;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #515154;
  --bs-btn-active-border-color: #3b3b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #252529;
  --bs-btn-disabled-border-color: #252529;
}

.btn-outline-primary {
  --bs-btn-color: #ff8800;
  --bs-btn-border-color: #ff8800;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ff8800;
  --bs-btn-hover-border-color: #ff8800;
  --bs-btn-focus-shadow-rgb: 255, 136, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ff8800;
  --bs-btn-active-border-color: #ff8800;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff8800;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff8800;
  --bs-gradient: none;
}

.btn-outline-accent {
  --bs-btn-color: #00406e;
  --bs-btn-border-color: #00406e;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #00406e;
  --bs-btn-hover-border-color: #00406e;
  --bs-btn-focus-shadow-rgb: 51, 85, 153;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #00406e;
  --bs-btn-active-border-color: #00406e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00406e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00406e;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #8e8e93;
  --bs-btn-border-color: #8e8e93;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #8e8e93;
  --bs-btn-hover-border-color: #8e8e93;
  --bs-btn-focus-shadow-rgb: 142, 142, 147;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #8e8e93;
  --bs-btn-active-border-color: #8e8e93;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #8e8e93;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8e8e93;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #42d78c;
  --bs-btn-border-color: #42d78c;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #42d78c;
  --bs-btn-hover-border-color: #42d78c;
  --bs-btn-focus-shadow-rgb: 66, 215, 140;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #42d78c;
  --bs-btn-active-border-color: #42d78c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #42d78c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #42d78c;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #f05564;
  --bs-btn-border-color: #f05564;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f05564;
  --bs-btn-hover-border-color: #f05564;
  --bs-btn-focus-shadow-rgb: 240, 85, 100;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f05564;
  --bs-btn-active-border-color: #f05564;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f05564;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f05564;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffcc18;
  --bs-btn-border-color: #ffcc18;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffcc18;
  --bs-btn-hover-border-color: #ffcc18;
  --bs-btn-focus-shadow-rgb: 255, 204, 24;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffcc18;
  --bs-btn-active-border-color: #ffcc18;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffcc18;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffcc18;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #058fff;
  --bs-btn-border-color: #058fff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #058fff;
  --bs-btn-hover-border-color: #058fff;
  --bs-btn-focus-shadow-rgb: 5, 143, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #058fff;
  --bs-btn-active-border-color: #058fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #058fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #058fff;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f6f6f7;
  --bs-btn-border-color: #f6f6f7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f6f6f7;
  --bs-btn-hover-border-color: #f6f6f7;
  --bs-btn-focus-shadow-rgb: 246, 246, 247;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f6f6f7;
  --bs-btn-active-border-color: #f6f6f7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f6f6f7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f6f6f7;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #252529;
  --bs-btn-border-color: #252529;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #252529;
  --bs-btn-hover-border-color: #252529;
  --bs-btn-focus-shadow-rgb: 37, 37, 41;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #252529;
  --bs-btn-active-border-color: #252529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #252529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #252529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #48484d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 217, 116, 0;
  text-decoration: none;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg,
.btn-group-lg>.btn {
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-padding-x: 1.375rem;
  --bs-btn-font-size: 1.0625rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm,
.btn-group-sm>.btn {
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 12rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.9375rem;
  --bs-dropdown-color: var(--bs-secondary-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08),
    0 2px 6px 0 rgba(0, 0, 0, 0.06);
  --bs-dropdown-link-color: var(--bs-secondary-color);
  --bs-dropdown-link-hover-color: #ff8800;
  --bs-dropdown-link-hover-bg: transparent;
  --bs-dropdown-link-active-color: #ff8800;
  --bs-dropdown-link-active-bg: transparent;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1.125rem;
  --bs-dropdown-item-padding-y: 0.375rem;
  --bs-dropdown-header-color: #48484d;
  --bs-dropdown-header-padding-x: 1.125rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #d5d5d8;
  --bs-dropdown-bg: #252529;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #d5d5d8;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #ff8800;
  --bs-dropdown-link-active-bg: transparent;
  --bs-dropdown-link-disabled-color: #8e8e93;
  --bs-dropdown-header-color: #8e8e93;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}

.btn-group> :not(.btn-check:first-child)+.btn,
.btn-group>.btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 1.03125rem;
  padding-left: 1.03125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1.25rem;
  --bs-nav-link-padding-y: 0.75rem;
  --bs-nav-link-font-weight: 400;
  --bs-nav-link-color: var(--bs-secondary-color);
  --bs-nav-link-hover-color: var(--bs-primary);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 136, 0, 0.25);
}

.nav-link.disabled,
.nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-primary);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-primary);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid rgba(0, 0, 0, 0);
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #ff8800;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid rgba(0, 0, 0, 0);
}

.nav-underline .nav-link:hover,
.nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.75rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.25rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.125rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.125rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2830, 30, 32, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.75rem;
  --bs-nav-link-font-weight: 400;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--bs-navbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: rgba(0, 0, 0, 0);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link+.card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group>.card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius-lg);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231e1e20'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23663600'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #ffc480;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(255, 136, 0, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-body-bg);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffb866'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffb866'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider,
      "/"
    );
  /* rtl: var(--bs-breadcrumb-divider, "/") */
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.9375rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(255, 136, 0, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #ff8800;
  --bs-pagination-active-border-color: #ff8800;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.125rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-accent {
  --bs-alert-color: var(--bs-accent-text-emphasis);
  --bs-alert-bg: var(--bs-accent-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-border-subtle);
  --bs-alert-link-color: var(--bs-accent-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #ff8800;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked>.progress {
  overflow: visible;
}

.progress-stacked>.progress>.progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #ff8800;
  --bs-list-group-active-border-color: #ff8800;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered>.list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-accent {
  --bs-list-group-color: var(--bs-accent-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(255, 136, 0, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: rgba(0, 0, 0, 0) var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}

.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08),
    0 2px 6px 0 rgba(0, 0, 0, 0.06);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
      0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 4px 16px 0 rgba(0, 0, 0, 0.06);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
    0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 4px 16px 0 rgba(0, 0, 0, 0.06);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #1e1e20;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 0;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon,
[data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption,
[data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
    /* rtl:ignore */
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: rgba(0, 0, 0, 0);
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: 0;
  --bs-offcanvas-border-color: transparent;
  --bs-offcanvas-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08),
    0 2px 6px 0 rgba(0, 0, 0, 0.06);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg,
      #000000 55%,
      rgba(0, 0, 0, 0.8) 75%,
      #000000 95%);
  mask-image: linear-gradient(130deg,
      #000000 55%,
      rgba(0, 0, 0, 0.8) 75%,
      #000000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(var(--bs-primary-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-accent {
  color: #fff !important;
  background-color: RGBA(var(--bs-accent-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(var(--bs-secondary-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(var(--bs-success-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(var(--bs-danger-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary:hover,
.link-primary:focus {
  color: RGBA(255, 154, 38, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(255,
      154,
      38,
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(255,
      154,
      38,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-accent {
  color: RGBA(var(--bs-accent-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-accent-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-accent-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-accent:hover,
.link-accent:focus {
  color: RGBA(43, 72, 130, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(43,
      72,
      130,
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(43,
      72,
      130,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary:hover,
.link-secondary:focus {
  color: RGBA(159, 159, 163, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(159,
      159,
      163,
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(159,
      159,
      163,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-success-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-success:hover,
.link-success:focus {
  color: RGBA(94, 221, 157, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(94,
      221,
      157,
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(94,
      221,
      157,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger:hover,
.link-danger:focus {
  color: RGBA(242, 111, 123, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(242,
      111,
      123,
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(242,
      111,
      123,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning:hover,
.link-warning:focus {
  color: RGBA(255, 212, 59, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(255,
      212,
      59,
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(255,
      212,
      59,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-info-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-info:hover,
.link-info:focus {
  color: RGBA(43, 160, 255, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(43,
      160,
      255,
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(43,
      160,
      255,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-light-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-light:hover,
.link-light:focus {
  color: RGBA(247, 247, 248, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(247,
      247,
      248,
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(247,
      247,
      248,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark:hover,
.link-dark:focus {
  color: RGBA(31, 31, 35, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(31,
      31,
      35,
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(31,
      31,
      35,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb),
      var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover,
.link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb),
      var(--bs-link-opacity, 0.75)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb),
      var(--bs-link-underline-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb),
      var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb),
      var(--bs-link-opacity, 0.5));
  text-decoration-color: rgba(var(--bs-link-color-rgb),
      var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.icon-link>.bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link>.bi {
    transition: none;
  }
}

.icon-link-hover:hover>.bi,
.icon-link-hover:focus-visible>.bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
  object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
  object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow:
    0 1px 4px 0 rgba(0, 0, 0, 0.1),
    0 2px 8px 0 rgba(0, 0, 0, 0.08),
    0 4px 16px 0 rgba(0, 0, 0, 0.06) !important;
}

.shadow-sm {
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.08),
    0 2px 6px 0 rgba(0, 0, 0, 0.06) !important;
}

.shadow-lg {
  box-shadow:
    0 1px 4px 0 rgba(0, 0, 0, 0.12),
    0 2px 8px 0 rgba(0, 0, 0, 0.1),
    0 4px 16px 0 rgba(0, 0, 0, 0.08) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-accent {
  --bs-focus-ring-color: rgba(var(--bs-accent-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-25 {
  top: 25% !important;
}

.top-50 {
  top: 50% !important;
}

.top-75 {
  top: 75% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-25 {
  left: 25% !important;
}

.start-50 {
  left: 50% !important;
}

.start-75 {
  left: 75% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-25 {
  right: 25% !important;
}

.end-50 {
  right: 50% !important;
}

.end-75 {
  right: 75% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb),
      var(--bs-border-opacity)) !important;
}

.border-accent {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-accent-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb),
      var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb),
      var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb),
      var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-6 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-7 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}

.mx-8 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-9 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-10 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.me-8 {
  margin-right: 2rem !important;
}

.me-9 {
  margin-right: 2.25rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.ms-8 {
  margin-left: 2rem !important;
}

.ms-9 {
  margin-left: 2.25rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.m-n9 {
  margin: -2.25rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n4 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n5 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n6 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n7 {
  margin-right: -1.75rem !important;
  margin-left: -1.75rem !important;
}

.mx-n8 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n9 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}

.mx-n10 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.25rem !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n7 {
  margin-top: -1.75rem !important;
}

.mt-n8 {
  margin-top: -2rem !important;
}

.mt-n9 {
  margin-top: -2.25rem !important;
}

.mt-n10 {
  margin-top: -2.5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -0.75rem !important;
}

.me-n4 {
  margin-right: -1rem !important;
}

.me-n5 {
  margin-right: -1.25rem !important;
}

.me-n6 {
  margin-right: -1.5rem !important;
}

.me-n7 {
  margin-right: -1.75rem !important;
}

.me-n8 {
  margin-right: -2rem !important;
}

.me-n9 {
  margin-right: -2.25rem !important;
}

.me-n10 {
  margin-right: -2.5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.25rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.mb-n7 {
  margin-bottom: -1.75rem !important;
}

.mb-n8 {
  margin-bottom: -2rem !important;
}

.mb-n9 {
  margin-bottom: -2.25rem !important;
}

.mb-n10 {
  margin-bottom: -2.5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -0.75rem !important;
}

.ms-n4 {
  margin-left: -1rem !important;
}

.ms-n5 {
  margin-left: -1.25rem !important;
}

.ms-n6 {
  margin-left: -1.5rem !important;
}

.ms-n7 {
  margin-left: -1.75rem !important;
}

.ms-n8 {
  margin-left: -2rem !important;
}

.ms-n9 {
  margin-left: -2.25rem !important;
}

.ms-n10 {
  margin-left: -2.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-7 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

.px-8 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-9 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-9 {
  padding-right: 2.25rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-9 {
  padding-left: 2.25rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 0.75rem !important;
}

.row-gap-4 {
  row-gap: 1rem !important;
}

.row-gap-5 {
  row-gap: 1.25rem !important;
}

.row-gap-6 {
  row-gap: 1.5rem !important;
}

.row-gap-7 {
  row-gap: 1.75rem !important;
}

.row-gap-8 {
  row-gap: 2rem !important;
}

.row-gap-9 {
  row-gap: 2.25rem !important;
}

.row-gap-10 {
  row-gap: 2.5rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 0.75rem !important;
  column-gap: 0.75rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}

.column-gap-5 {
  -moz-column-gap: 1.25rem !important;
  column-gap: 1.25rem !important;
}

.column-gap-6 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

.column-gap-7 {
  -moz-column-gap: 1.75rem !important;
  column-gap: 1.75rem !important;
}

.column-gap-8 {
  -moz-column-gap: 2rem !important;
  column-gap: 2rem !important;
}

.column-gap-9 {
  -moz-column-gap: 2.25rem !important;
  column-gap: 2.25rem !important;
}

.column-gap-10 {
  -moz-column-gap: 2.5rem !important;
  column-gap: 2.5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-xl {
  font-size: calc(1.2875rem + 0.45vw) !important;
}

.fs-lg {
  font-size: 1.125rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-md {
  font-size: 0.9375rem !important;
}

.fs-sm {
  font-size: 0.875rem !important;
}

.fs-ms {
  font-size: 0.8125rem !important;
}

.fs-xs {
  font-size: 0.75rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 500 !important;
}

.fw-normal {
  font-weight: 600 !important;
}

.fw-medium {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 800 !important;
}

.fw-bold {
  font-weight: 900 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-accent {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-accent-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-primary-rgb),
      var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-primary-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-accent {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-accent-rgb),
      var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-accent-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb),
      var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-secondary-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-success-rgb),
      var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-success-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-danger-rgb),
      var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-danger-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-warning-rgb),
      var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-warning-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-info-rgb),
      var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-info-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-light-rgb),
      var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-light-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-dark-rgb),
      var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-dark-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: rgba(var(--bs-link-color-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-accent {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-accent-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 0) !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.min-width-0 {
  min-width: 0 !important;
}

.table-layout-fixed {
  table-layout: fixed !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.25rem !important;
  }

  .m-sm-6 {
    margin: 1.5rem !important;
  }

  .m-sm-7 {
    margin: 1.75rem !important;
  }

  .m-sm-8 {
    margin: 2rem !important;
  }

  .m-sm-9 {
    margin: 2.25rem !important;
  }

  .m-sm-10 {
    margin: 2.5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-sm-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-sm-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-sm-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mt-sm-4 {
    margin-top: 1rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }

  .mt-sm-8 {
    margin-top: 2rem !important;
  }

  .mt-sm-9 {
    margin-top: 2.25rem !important;
  }

  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 0.75rem !important;
  }

  .me-sm-4 {
    margin-right: 1rem !important;
  }

  .me-sm-5 {
    margin-right: 1.25rem !important;
  }

  .me-sm-6 {
    margin-right: 1.5rem !important;
  }

  .me-sm-7 {
    margin-right: 1.75rem !important;
  }

  .me-sm-8 {
    margin-right: 2rem !important;
  }

  .me-sm-9 {
    margin-right: 2.25rem !important;
  }

  .me-sm-10 {
    margin-right: 2.5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }

  .ms-sm-4 {
    margin-left: 1rem !important;
  }

  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }

  .ms-sm-8 {
    margin-left: 2rem !important;
  }

  .ms-sm-9 {
    margin-left: 2.25rem !important;
  }

  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -0.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.25rem !important;
  }

  .m-sm-n6 {
    margin: -1.5rem !important;
  }

  .m-sm-n7 {
    margin: -1.75rem !important;
  }

  .m-sm-n8 {
    margin: -2rem !important;
  }

  .m-sm-n9 {
    margin: -2.25rem !important;
  }

  .m-sm-n10 {
    margin: -2.5rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-sm-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-sm-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-sm-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-sm-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-sm-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1rem !important;
  }

  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-sm-n8 {
    margin-top: -2rem !important;
  }

  .mt-sm-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-sm-n10 {
    margin-top: -2.5rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }

  .me-sm-n4 {
    margin-right: -1rem !important;
  }

  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }

  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }

  .me-sm-n8 {
    margin-right: -2rem !important;
  }

  .me-sm-n9 {
    margin-right: -2.25rem !important;
  }

  .me-sm-n10 {
    margin-right: -2.5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -2.5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1rem !important;
  }

  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-sm-n8 {
    margin-left: -2rem !important;
  }

  .ms-sm-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-sm-n10 {
    margin-left: -2.5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.25rem !important;
  }

  .p-sm-6 {
    padding: 1.5rem !important;
  }

  .p-sm-7 {
    padding: 1.75rem !important;
  }

  .p-sm-8 {
    padding: 2rem !important;
  }

  .p-sm-9 {
    padding: 2.25rem !important;
  }

  .p-sm-10 {
    padding: 2.5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-sm-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-sm-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-sm-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pt-sm-4 {
    padding-top: 1rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }

  .pt-sm-8 {
    padding-top: 2rem !important;
  }

  .pt-sm-9 {
    padding-top: 2.25rem !important;
  }

  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pe-sm-4 {
    padding-right: 1rem !important;
  }

  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }

  .pe-sm-8 {
    padding-right: 2rem !important;
  }

  .pe-sm-9 {
    padding-right: 2.25rem !important;
  }

  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }

  .ps-sm-4 {
    padding-left: 1rem !important;
  }

  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }

  .ps-sm-8 {
    padding-left: 2rem !important;
  }

  .ps-sm-9 {
    padding-left: 2.25rem !important;
  }

  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 0.75rem !important;
  }

  .gap-sm-4 {
    gap: 1rem !important;
  }

  .gap-sm-5 {
    gap: 1.25rem !important;
  }

  .gap-sm-6 {
    gap: 1.5rem !important;
  }

  .gap-sm-7 {
    gap: 1.75rem !important;
  }

  .gap-sm-8 {
    gap: 2rem !important;
  }

  .gap-sm-9 {
    gap: 2.25rem !important;
  }

  .gap-sm-10 {
    gap: 2.5rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-sm-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-sm-8 {
    row-gap: 2rem !important;
  }

  .row-gap-sm-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-sm-10 {
    row-gap: 2.5rem !important;
  }

  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }

  .column-gap-sm-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-sm-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }

  .column-gap-sm-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }

  .column-gap-sm-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-sm-9 {
    -moz-column-gap: 2.25rem !important;
    column-gap: 2.25rem !important;
  }

  .column-gap-sm-10 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .text-sm-justify {
    text-align: justify !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .m-md-5 {
    margin: 1.25rem !important;
  }

  .m-md-6 {
    margin: 1.5rem !important;
  }

  .m-md-7 {
    margin: 1.75rem !important;
  }

  .m-md-8 {
    margin: 2rem !important;
  }

  .m-md-9 {
    margin: 2.25rem !important;
  }

  .m-md-10 {
    margin: 2.5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-md-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-md-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-md-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 0.75rem !important;
  }

  .mt-md-4 {
    margin-top: 1rem !important;
  }

  .mt-md-5 {
    margin-top: 1.25rem !important;
  }

  .mt-md-6 {
    margin-top: 1.5rem !important;
  }

  .mt-md-7 {
    margin-top: 1.75rem !important;
  }

  .mt-md-8 {
    margin-top: 2rem !important;
  }

  .mt-md-9 {
    margin-top: 2.25rem !important;
  }

  .mt-md-10 {
    margin-top: 2.5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 0.75rem !important;
  }

  .me-md-4 {
    margin-right: 1rem !important;
  }

  .me-md-5 {
    margin-right: 1.25rem !important;
  }

  .me-md-6 {
    margin-right: 1.5rem !important;
  }

  .me-md-7 {
    margin-right: 1.75rem !important;
  }

  .me-md-8 {
    margin-right: 2rem !important;
  }

  .me-md-9 {
    margin-right: 2.25rem !important;
  }

  .me-md-10 {
    margin-right: 2.5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2rem !important;
  }

  .mb-md-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 0.75rem !important;
  }

  .ms-md-4 {
    margin-left: 1rem !important;
  }

  .ms-md-5 {
    margin-left: 1.25rem !important;
  }

  .ms-md-6 {
    margin-left: 1.5rem !important;
  }

  .ms-md-7 {
    margin-left: 1.75rem !important;
  }

  .ms-md-8 {
    margin-left: 2rem !important;
  }

  .ms-md-9 {
    margin-left: 2.25rem !important;
  }

  .ms-md-10 {
    margin-left: 2.5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -0.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.25rem !important;
  }

  .m-md-n6 {
    margin: -1.5rem !important;
  }

  .m-md-n7 {
    margin: -1.75rem !important;
  }

  .m-md-n8 {
    margin: -2rem !important;
  }

  .m-md-n9 {
    margin: -2.25rem !important;
  }

  .m-md-n10 {
    margin: -2.5rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-md-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-md-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-md-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-md-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-md-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-md-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-md-n4 {
    margin-top: -1rem !important;
  }

  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-md-n8 {
    margin-top: -2rem !important;
  }

  .mt-md-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-md-n10 {
    margin-top: -2.5rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -0.75rem !important;
  }

  .me-md-n4 {
    margin-right: -1rem !important;
  }

  .me-md-n5 {
    margin-right: -1.25rem !important;
  }

  .me-md-n6 {
    margin-right: -1.5rem !important;
  }

  .me-md-n7 {
    margin-right: -1.75rem !important;
  }

  .me-md-n8 {
    margin-right: -2rem !important;
  }

  .me-md-n9 {
    margin-right: -2.25rem !important;
  }

  .me-md-n10 {
    margin-right: -2.5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -2.5rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-md-n4 {
    margin-left: -1rem !important;
  }

  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-md-n8 {
    margin-left: -2rem !important;
  }

  .ms-md-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-md-n10 {
    margin-left: -2.5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .p-md-5 {
    padding: 1.25rem !important;
  }

  .p-md-6 {
    padding: 1.5rem !important;
  }

  .p-md-7 {
    padding: 1.75rem !important;
  }

  .p-md-8 {
    padding: 2rem !important;
  }

  .p-md-9 {
    padding: 2.25rem !important;
  }

  .p-md-10 {
    padding: 2.5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-md-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-md-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-md-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 0.75rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .pt-md-5 {
    padding-top: 1.25rem !important;
  }

  .pt-md-6 {
    padding-top: 1.5rem !important;
  }

  .pt-md-7 {
    padding-top: 1.75rem !important;
  }

  .pt-md-8 {
    padding-top: 2rem !important;
  }

  .pt-md-9 {
    padding-top: 2.25rem !important;
  }

  .pt-md-10 {
    padding-top: 2.5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 0.75rem !important;
  }

  .pe-md-4 {
    padding-right: 1rem !important;
  }

  .pe-md-5 {
    padding-right: 1.25rem !important;
  }

  .pe-md-6 {
    padding-right: 1.5rem !important;
  }

  .pe-md-7 {
    padding-right: 1.75rem !important;
  }

  .pe-md-8 {
    padding-right: 2rem !important;
  }

  .pe-md-9 {
    padding-right: 2.25rem !important;
  }

  .pe-md-10 {
    padding-right: 2.5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2rem !important;
  }

  .pb-md-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 0.75rem !important;
  }

  .ps-md-4 {
    padding-left: 1rem !important;
  }

  .ps-md-5 {
    padding-left: 1.25rem !important;
  }

  .ps-md-6 {
    padding-left: 1.5rem !important;
  }

  .ps-md-7 {
    padding-left: 1.75rem !important;
  }

  .ps-md-8 {
    padding-left: 2rem !important;
  }

  .ps-md-9 {
    padding-left: 2.25rem !important;
  }

  .ps-md-10 {
    padding-left: 2.5rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 0.75rem !important;
  }

  .gap-md-4 {
    gap: 1rem !important;
  }

  .gap-md-5 {
    gap: 1.25rem !important;
  }

  .gap-md-6 {
    gap: 1.5rem !important;
  }

  .gap-md-7 {
    gap: 1.75rem !important;
  }

  .gap-md-8 {
    gap: 2rem !important;
  }

  .gap-md-9 {
    gap: 2.25rem !important;
  }

  .gap-md-10 {
    gap: 2.5rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1rem !important;
  }

  .row-gap-md-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-md-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-md-8 {
    row-gap: 2rem !important;
  }

  .row-gap-md-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-md-10 {
    row-gap: 2.5rem !important;
  }

  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }

  .column-gap-md-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-md-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }

  .column-gap-md-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-md-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }

  .column-gap-md-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-md-9 {
    -moz-column-gap: 2.25rem !important;
    column-gap: 2.25rem !important;
  }

  .column-gap-md-10 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-justify {
    text-align: justify !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.25rem !important;
  }

  .m-lg-6 {
    margin: 1.5rem !important;
  }

  .m-lg-7 {
    margin: 1.75rem !important;
  }

  .m-lg-8 {
    margin: 2rem !important;
  }

  .m-lg-9 {
    margin: 2.25rem !important;
  }

  .m-lg-10 {
    margin: 2.5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-lg-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-lg-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-lg-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mt-lg-4 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }

  .mt-lg-8 {
    margin-top: 2rem !important;
  }

  .mt-lg-9 {
    margin-top: 2.25rem !important;
  }

  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 0.75rem !important;
  }

  .me-lg-4 {
    margin-right: 1rem !important;
  }

  .me-lg-5 {
    margin-right: 1.25rem !important;
  }

  .me-lg-6 {
    margin-right: 1.5rem !important;
  }

  .me-lg-7 {
    margin-right: 1.75rem !important;
  }

  .me-lg-8 {
    margin-right: 2rem !important;
  }

  .me-lg-9 {
    margin-right: 2.25rem !important;
  }

  .me-lg-10 {
    margin-right: 2.5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }

  .ms-lg-4 {
    margin-left: 1rem !important;
  }

  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }

  .ms-lg-8 {
    margin-left: 2rem !important;
  }

  .ms-lg-9 {
    margin-left: 2.25rem !important;
  }

  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -0.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.25rem !important;
  }

  .m-lg-n6 {
    margin: -1.5rem !important;
  }

  .m-lg-n7 {
    margin: -1.75rem !important;
  }

  .m-lg-n8 {
    margin: -2rem !important;
  }

  .m-lg-n9 {
    margin: -2.25rem !important;
  }

  .m-lg-n10 {
    margin: -2.5rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-lg-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-lg-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-lg-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-lg-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-lg-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1rem !important;
  }

  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-lg-n8 {
    margin-top: -2rem !important;
  }

  .mt-lg-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-lg-n10 {
    margin-top: -2.5rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }

  .me-lg-n4 {
    margin-right: -1rem !important;
  }

  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }

  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }

  .me-lg-n8 {
    margin-right: -2rem !important;
  }

  .me-lg-n9 {
    margin-right: -2.25rem !important;
  }

  .me-lg-n10 {
    margin-right: -2.5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -2.5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1rem !important;
  }

  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-lg-n8 {
    margin-left: -2rem !important;
  }

  .ms-lg-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-lg-n10 {
    margin-left: -2.5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.25rem !important;
  }

  .p-lg-6 {
    padding: 1.5rem !important;
  }

  .p-lg-7 {
    padding: 1.75rem !important;
  }

  .p-lg-8 {
    padding: 2rem !important;
  }

  .p-lg-9 {
    padding: 2.25rem !important;
  }

  .p-lg-10 {
    padding: 2.5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-lg-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-lg-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-lg-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pt-lg-4 {
    padding-top: 1rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }

  .pt-lg-8 {
    padding-top: 2rem !important;
  }

  .pt-lg-9 {
    padding-top: 2.25rem !important;
  }

  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pe-lg-4 {
    padding-right: 1rem !important;
  }

  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }

  .pe-lg-8 {
    padding-right: 2rem !important;
  }

  .pe-lg-9 {
    padding-right: 2.25rem !important;
  }

  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }

  .ps-lg-4 {
    padding-left: 1rem !important;
  }

  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }

  .ps-lg-8 {
    padding-left: 2rem !important;
  }

  .ps-lg-9 {
    padding-left: 2.25rem !important;
  }

  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 0.75rem !important;
  }

  .gap-lg-4 {
    gap: 1rem !important;
  }

  .gap-lg-5 {
    gap: 1.25rem !important;
  }

  .gap-lg-6 {
    gap: 1.5rem !important;
  }

  .gap-lg-7 {
    gap: 1.75rem !important;
  }

  .gap-lg-8 {
    gap: 2rem !important;
  }

  .gap-lg-9 {
    gap: 2.25rem !important;
  }

  .gap-lg-10 {
    gap: 2.5rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-lg-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-lg-8 {
    row-gap: 2rem !important;
  }

  .row-gap-lg-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-lg-10 {
    row-gap: 2.5rem !important;
  }

  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }

  .column-gap-lg-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-lg-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }

  .column-gap-lg-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }

  .column-gap-lg-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-lg-9 {
    -moz-column-gap: 2.25rem !important;
    column-gap: 2.25rem !important;
  }

  .column-gap-lg-10 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .text-lg-justify {
    text-align: justify !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.25rem !important;
  }

  .m-xl-6 {
    margin: 1.5rem !important;
  }

  .m-xl-7 {
    margin: 1.75rem !important;
  }

  .m-xl-8 {
    margin: 2rem !important;
  }

  .m-xl-9 {
    margin: 2.25rem !important;
  }

  .m-xl-10 {
    margin: 2.5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xl-4 {
    margin-top: 1rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xl-8 {
    margin-top: 2rem !important;
  }

  .mt-xl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xl-4 {
    margin-right: 1rem !important;
  }

  .me-xl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xl-8 {
    margin-right: 2rem !important;
  }

  .me-xl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xl-4 {
    margin-left: 1rem !important;
  }

  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xl-8 {
    margin-left: 2rem !important;
  }

  .ms-xl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -0.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.25rem !important;
  }

  .m-xl-n6 {
    margin: -1.5rem !important;
  }

  .m-xl-n7 {
    margin: -1.75rem !important;
  }

  .m-xl-n8 {
    margin: -2rem !important;
  }

  .m-xl-n9 {
    margin: -2.25rem !important;
  }

  .m-xl-n10 {
    margin: -2.5rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xl-n10 {
    margin-top: -2.5rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xl-n4 {
    margin-right: -1rem !important;
  }

  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xl-n8 {
    margin-right: -2rem !important;
  }

  .me-xl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xl-n10 {
    margin-right: -2.5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xl-n10 {
    margin-left: -2.5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.25rem !important;
  }

  .p-xl-6 {
    padding: 1.5rem !important;
  }

  .p-xl-7 {
    padding: 1.75rem !important;
  }

  .p-xl-8 {
    padding: 2rem !important;
  }

  .p-xl-9 {
    padding: 2.25rem !important;
  }

  .p-xl-10 {
    padding: 2.5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xl-4 {
    padding-top: 1rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xl-8 {
    padding-top: 2rem !important;
  }

  .pt-xl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xl-4 {
    padding-right: 1rem !important;
  }

  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xl-8 {
    padding-right: 2rem !important;
  }

  .pe-xl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xl-4 {
    padding-left: 1rem !important;
  }

  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xl-8 {
    padding-left: 2rem !important;
  }

  .ps-xl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 0.75rem !important;
  }

  .gap-xl-4 {
    gap: 1rem !important;
  }

  .gap-xl-5 {
    gap: 1.25rem !important;
  }

  .gap-xl-6 {
    gap: 1.5rem !important;
  }

  .gap-xl-7 {
    gap: 1.75rem !important;
  }

  .gap-xl-8 {
    gap: 2rem !important;
  }

  .gap-xl-9 {
    gap: 2.25rem !important;
  }

  .gap-xl-10 {
    gap: 2.5rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-xl-8 {
    row-gap: 2rem !important;
  }

  .row-gap-xl-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-xl-10 {
    row-gap: 2.5rem !important;
  }

  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }

  .column-gap-xl-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-xl-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }

  .column-gap-xl-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }

  .column-gap-xl-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-xl-9 {
    -moz-column-gap: 2.25rem !important;
    column-gap: 2.25rem !important;
  }

  .column-gap-xl-10 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .text-xl-justify {
    text-align: justify !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.25rem !important;
  }

  .m-xxl-6 {
    margin: 1.5rem !important;
  }

  .m-xxl-7 {
    margin: 1.75rem !important;
  }

  .m-xxl-8 {
    margin: 2rem !important;
  }

  .m-xxl-9 {
    margin: 2.25rem !important;
  }

  .m-xxl-10 {
    margin: 2.5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xxl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xxl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xxl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2rem !important;
  }

  .mt-xxl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xxl-4 {
    margin-right: 1rem !important;
  }

  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xxl-8 {
    margin-right: 2rem !important;
  }

  .me-xxl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1rem !important;
  }

  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xxl-8 {
    margin-left: 2rem !important;
  }

  .ms-xxl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -0.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.25rem !important;
  }

  .m-xxl-n6 {
    margin: -1.5rem !important;
  }

  .m-xxl-n7 {
    margin: -1.75rem !important;
  }

  .m-xxl-n8 {
    margin: -2rem !important;
  }

  .m-xxl-n9 {
    margin: -2.25rem !important;
  }

  .m-xxl-n10 {
    margin: -2.5rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xxl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xxl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xxl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -2.5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1rem !important;
  }

  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xxl-n8 {
    margin-right: -2rem !important;
  }

  .me-xxl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xxl-n10 {
    margin-right: -2.5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -2.5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 0.75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.25rem !important;
  }

  .p-xxl-6 {
    padding: 1.5rem !important;
  }

  .p-xxl-7 {
    padding: 1.75rem !important;
  }

  .p-xxl-8 {
    padding: 2rem !important;
  }

  .p-xxl-9 {
    padding: 2.25rem !important;
  }

  .p-xxl-10 {
    padding: 2.5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xxl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xxl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xxl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2rem !important;
  }

  .pt-xxl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1rem !important;
  }

  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xxl-8 {
    padding-right: 2rem !important;
  }

  .pe-xxl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1rem !important;
  }

  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xxl-8 {
    padding-left: 2rem !important;
  }

  .ps-xxl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 0.75rem !important;
  }

  .gap-xxl-4 {
    gap: 1rem !important;
  }

  .gap-xxl-5 {
    gap: 1.25rem !important;
  }

  .gap-xxl-6 {
    gap: 1.5rem !important;
  }

  .gap-xxl-7 {
    gap: 1.75rem !important;
  }

  .gap-xxl-8 {
    gap: 2rem !important;
  }

  .gap-xxl-9 {
    gap: 2.25rem !important;
  }

  .gap-xxl-10 {
    gap: 2.5rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xxl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-xxl-8 {
    row-gap: 2rem !important;
  }

  .row-gap-xxl-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-xxl-10 {
    row-gap: 2.5rem !important;
  }

  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }

  .column-gap-xxl-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-xxl-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }

  .column-gap-xxl-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }

  .column-gap-xxl-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }

  .column-gap-xxl-9 {
    -moz-column-gap: 2.25rem !important;
    column-gap: 2.25rem !important;
  }

  .column-gap-xxl-10 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .text-xxl-justify {
    text-align: justify !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }

  .fs-xl {
    font-size: 1.625rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

[data-bs-theme="dark"] {
  color-scheme: dark;
}

.link-hover-primary:hover,
.link-hover-primary:focus {
  color: #f80 !important;
}

.link-hover-accent:hover,
.link-hover-accent:focus {
  color: #359 !important;
}

.link-hover-secondary:hover,
.link-hover-secondary:focus {
  color: #8e8e93 !important;
}

.link-hover-success:hover,
.link-hover-success:focus {
  color: #42d78c !important;
}

.link-hover-danger:hover,
.link-hover-danger:focus {
  color: #f05564 !important;
}

.link-hover-warning:hover,
.link-hover-warning:focus {
  color: #ffcc18 !important;
}

.link-hover-info:hover,
.link-hover-info:focus {
  color: #058fff !important;
}

.link-hover-light:hover,
.link-hover-light:focus {
  color: #f6f6f7 !important;
}

.link-hover-dark:hover,
.link-hover-dark:focus {
  color: #252529 !important;
}

.transition-off * {
  transition-duration: 0s !important;
}

.btn-primary,
.btn-success,
.btn-danger,
.btn-warning,
.btn-info {
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-disabled-color: var(--bs-white);
}

.btn-secondary {
  --bs-btn-color: var(--bs-gray-100);
  --bs-btn-hover-color: var(--bs-gray-100);
  --bs-btn-active-color: var(--bs-gray-100);
  --bs-btn-disabled-color: var(--bs-gray-300);
}

.btn-light {
  --bs-btn-color: var(--bs-gray-900);
  --bs-btn-hover-color: var(--bs-gray-900);
  --bs-btn-active-color: var(--bs-gray-900);
  --bs-btn-disabled-color: var(--bs-gray-700);
}

[data-bs-theme="dark"] .btn-secondary,
[data-bs-theme="dark"] .btn-light {
  --bs-btn-color: #ffffff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-active-color: #ffffff;
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-bg: #313136;
  --bs-btn-active-bg: #48484d;
  --bs-btn-active-border-color: #48484d;
  --bs-btn-hover-bg: #48484d;
  --bs-btn-border-color: #313136;
  --bs-btn-hover-border-color: #313136;
  --bs-btn-active-border-color: #48484d;
}

.btn-outline-primary,
.btn-outline-success,
.btn-outline-danger,
.btn-outline-warning,
.btn-outline-info {
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
}

.btn-outline-secondary,
.btn-outline-light {
  --bs-btn-color: var(--bs-gray-500);
  --bs-btn-hover-color: var(--bs-black);
  --bs-btn-active-color: var(--bs-black);
  --bs-btn-disabled-color: var(--bs-gray-500);
}

.btn-add {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 6.25rem;
  height: 6.25rem;
  font-size: 5rem;
  border: 0;
  border-radius: 50%;
  color: var(--bs-body-bg);
  background-color: var(--bs-gray-500);
}

.dropdown-menu {
  background-clip: border-box;
}

.dropdown-menu li:hover>.dropdown-item {
  color: #f80;
}

.dropdown-toggle {
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
}

.dropdown-toggle::after {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "remixicon";
  font-size: 1.5rem;
  width: 0.625rem;
  height: 0.625rem;
  margin-left: 0.255em;
  color: inherit;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropup .dropdown-toggle::after {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "remixicon";
  font-size: 1.5rem;
  width: 0.625rem;
  height: 0.625rem;
  margin-left: 0.255em;
  color: inherit;
  content: "î©–";
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "remixicon";
  font-size: 1.5rem;
  width: 0.625rem;
  height: 0.625rem;
  margin-left: 0.255em;
  color: inherit;
  content: "î©”";
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::after {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "remixicon";
  font-size: 1.5rem;
  width: 0.625rem;
  height: 0.625rem;
  margin-left: 0.255em;
  color: inherit;
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "remixicon";
  font-size: 1.5rem;
  width: 0.625rem;
  height: 0.625rem;
  margin-right: 0.255em;
  color: inherit;
  content: "î©’";
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-0.5rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.form-control {
  background-clip: border-box;
}

.form-check-label {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.form-switch .form-check-input {
  height: 1.25rem;
  border: 0;
  background-color: #cdcdce;
}

.form-switch .form-check-input:checked {
  background-color: #f80;
  box-shadow: 0 6px 12px -6px #f80;
}

.form-switch .form-check-input:disabled {
  box-shadow: none;
}

.form-switch .form-check-label {
  margin-top: 0.1875rem;
}

.form-option {
  --bs-form-option-width: 1.75rem;
  --bs-form-option-height: 1.75rem;
  --bs-form-option-border-width: 2px;
  --bs-form-option-border-color: var(--bs-border-color);
  --bs-form-option-hover-border-color: #c0c0c2;
  --bs-form-option-active-border-color: #ffb866;
  --bs-form-option-border-radius: 0.25rem;
  --bs-form-option-font-size: 0.75rem;
  --bs-form-option-font-weight: 400;
  --bs-form-option-color: var(--bs-body-color);
  --bs-form-option-hover-color: var(--bs-body-color);
  --bs-form-option-active-color: #ff8800;
  --bs-form-option-transition: color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  padding-left: 0;
}

.form-option.form-check-inline {
  margin-right: 0.5rem;
}

.form-option .form-check-input {
  border: 0;
  background: none;
  box-shadow: none;
}

.form-option-label {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: var(--bs-form-option-width);
  height: var(--bs-form-option-height);
  margin-bottom: 0;
  transition: var(--bs-form-option-transition);
  border: var(--bs-form-option-border-width) solid var(--bs-form-option-border-color);
  border-radius: var(--bs-form-option-border-radius);
  color: var(--bs-form-option-color);
  font-size: var(--bs-form-option-font-size);
  font-weight: var(--bs-form-option-font-weight);
  line-height: calc(var(--bs-form-option-font-size) - 0.25rem);
  text-align: center;
  cursor: pointer;
}

.form-option-label:hover {
  border-color: var(--bs-form-option-hover-border-color);
  color: var(--bs-form-option-hover-color);
}

.form-option-color {
  display: block;
  width: calc(var(--bs-form-option-width) - 0.5rem);
  height: calc(var(--bs-form-option-height) - 0.5rem);
  background-position: top left;
  background-size: calc(var(--bs-form-option-width) - 0.5rem) calc(var(--bs-form-option-height) - 0.5rem);
  background-repeat: no-repeat;
}

.form-check-input:checked~.form-option-label {
  border-color: var(--bs-form-option-active-border-color);
  color: var(--bs-form-option-active-color);
}

[data-bs-theme="dark"] .form-option {
  --bs-form-option-hover-border-color: #48484d;
  --bs-form-option-active-border-color: #ff941a;
}

.quantity-products {
  display: inline-flex;
  align-items: stretch;
}

.quantity-products *:not(:last-child) {
  margin-right: calc(var(--bs-border-width) * -1);
}

.quantity-btn-minus,
.quantity-btn-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: auto;
  border: var(--bs-border-width) solid var(--bs-border-color);
  color: var(--bs-secondary-color);
  background-color: rgba(0, 0, 0, 0);
  outline: 0;
  transition: color 0.25s;
}

.quantity-btn-minus:hover,
.quantity-btn-plus:hover {
  color: var(--bs-primary);
}

.quantity-btn-minus {
  border-top-left-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.quantity-btn-plus {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.quantity-number {
  width: 2.5rem;
  padding: 0.6875rem 0;
  font-size: 0.875rem;
  text-align: center;
  border: var(--bs-border-width) solid var(--bs-border-color);
  color: var(--bs-body-color);
  background-color: rgba(0, 0, 0, 0);
}

.quantity-number:focus {
  outline: 0;
}

.quantity-number::-webkit-outer-spin-button,
.quantity-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity-number[type="number"] {
  -moz-appearance: textfield;
}

.nav-tabs {
  margin-bottom: 1.25rem;
}

.nav-tabs .nav-link {
  position: relative;
  border: 0;
}

.nav-tabs .nav-link::after {
  content: "";
  position: absolute;
  display: block;
  left: 1.25rem;
  bottom: 0;
  width: calc(100% - (1.25rem * 2));
  height: 2px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  background-color: rgba(0, 0, 0, 0);
}

.nav-tabs .nav-link.active::after {
  background-color: var(--bs-primary);
}

.nav-tabs.nav-fill .nav-link::after,
.nav-tabs.nav-justified .nav-link::after {
  left: 0;
  width: 100%;
}

.nav-pills .nav-item {
  margin-right: 0.625rem;
}

.nav-pills .nav-item:last-child {
  margin-right: 0;
}

.nav-pills.flex-column .nav-item {
  margin-right: 0;
  margin-bottom: 0.625rem;
}

.nav-pills.flex-column .nav-item:last-child {
  margin-bottom: 0;
}

.nav-pills .nav-link {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  font-size: 0.9375rem;
  color: var(--bs-secondary-color);
  background-color: var(--bs-secondary-bg);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.nav-pills .nav-link:hover:not(.active):not([aria-expanded="true"]),
.nav-pills .nav-link.show {
  color: var(--bs-secondary-color);
  background-color: var(--bs-border-color);
}

.nav-pills .nav-link.disabled {
  color: var(--bs-secondary-color);
  background-color: var(--bs-secondary-bg);
}

.nav-pills .nav-link.disabled:hover:not(.active) {
  background-color: rgba(0, 0, 0, 0);
}

.nav-pills .nav-link.active {
  box-shadow: 0 6px 12px -6px rgba(255, 136, 0, 0.8);
}

.nav-pills .nav-link i {
  font-size: 0.9375rem;
  margin-top: -0.0625rem;
}

.nav-link-base {
  color: var(--bs-secondary-color);
}

.nav-link-base>i {
  display: inline-block;
  margin-top: -0.125rem;
  vertical-align: middle;
}

.nav-link-base:hover {
  color: var(--bs-primary);
}

.offcanvas {
  visibility: visible !important;
}

.offcanvas:not(.offcanvas-end):not(.offcanvas-bottom) {
  top: 0;
  left: 0;
  width: 400px;
}

.offcanvas.show {
  transform: none !important;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.08),
    0 2px 6px 0 rgba(0, 0, 0, 0.06) !important;
}

.offcanvas.rounded-3 {
  border-radius: 0 !important;
}

@supports (scrollbar-width: thin) {
  .offcanvas-body {
    scrollbar-width: thin;
    scrollbar-color: var(--bs-gray-500) var(--bs-secondary-bg);
  }
}

.offcanvas-body::-webkit-scrollbar {
  width: 0.375rem;
}

.offcanvas-body::-webkit-scrollbar-track {
  background-color: var(--bs-secondary-bg);
}

.offcanvas-body::-webkit-scrollbar-thumb {
  background-color: var(--bs-gray-500);
  border-radius: 0.1875rem;
}

@media (min-width: 992px) {
  .offcanvas-collapse {
    display: block;
    position: static;
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
    width: 100% !important;
    height: auto !important;
    transform: none !important;
    background-color: rgba(0, 0, 0, 0);
  }

  .offcanvas-collapse .offcanvas-header,
  .offcanvas-collapse .offcanvas-footer {
    display: none;
  }

  .offcanvas-collapse .offcanvas-body {
    padding: 0;
    overflow: initial;
  }

  .offcanvas-collapse.rounded-3 {
    border-radius: 0.5rem !important;
  }
}

.pagination {
  margin: 0;
}

.page-item {
  margin-right: 0.5rem;
  font-size: 0.9375rem;
}

.page-item:last-child {
  margin-right: 0;
}

.page-item .page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}

.page-item .page-link i {
  margin-top: -1px;
  font-size: 1.25rem;
  vertical-align: middle;
}

.page-item.active .page-link {
  box-shadow: 0 4px 12px rgba(255, 136, 0, 0.2);
}

.page-item .page-link:focus {
  border: var(--bs-border-width) solid var(--bs-secondary-bg);
}

.page-item .page-link.next,
.page-item .page-link.previous {
  height: 2.375rem;
  width: 2.375rem;
}

.page-item.active .page-link:focus {
  border-color: var(--bs-primary);
}

.pagination .page-link {
  border-radius: var(--bs-border-radius);
  font-size: 0.9375rem;
}

.pagination-sm .page-link {
  border-radius: var(--bs-border-radius-sm);
  font-size: 0.8125rem;
}

.pagination-lg .page-link {
  border-radius: var(--bs-border-radius-lg);
  font-size: 1rem;
}

.breadcrumb-item::before {
  margin-top: 0.0625rem;
  font-family: "remixicon";
  font-size: 1em;
  vertical-align: middle;
}

.breadcrumb-item>a {
  color: var(--bs-accent);
}

.breadcrumb-item>a:hover {
  color: var(--bs-primary);
}

.breadcrumb-item>a>i {
  margin-top: -0.125rem;
  margin-right: 0.375rem;
  font-size: 1.05em;
  vertical-align: middle;
}

.breadcrumb-item.active {
  cursor: default;
}

.accordion-button {
  font-weight: 500;
}

.accordion-button:not(.collapsed) {
  box-shadow: inset 0 calc(var(--bs-border-width) * -1) 0 var(--bs-border-color);
}

.alert-link {
  text-decoration: underline;
}

.alert-link:hover {
  text-decoration: none;
}

.figure {
  padding: 0.375rem;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-color: var(--bs-body-bg);
}

.figure-caption {
  padding: 0 0.375rem;
}

.list-group-item-action {
  transition: all 0.25s ease-in-out;
}

.list-group-item-action:active {
  transition: none;
}

.list-group-item.active {
  box-shadow: 0 8px 16px -8px rgba(255, 136, 0, 0.5);
}

.popover {
  box-shadow:
    0 1px 4px 0 rgba(0, 0, 0, 0.1),
    0 2px 8px 0 rgba(0, 0, 0, 0.08),
    0 4px 16px 0 rgba(0, 0, 0, 0.06);
}

.popover-header {
  font-size: 1rem;
}

.toast-header [class*="ri-"] {
  font-size: 1.25em;
  line-height: 1;
}

.blockquote {
  position: relative;
  padding-left: 2.5rem;
}

.blockquote::before {
  content: "î±‘";
  position: absolute;
  left: 0;
  top: -12px;
  font-family: "remixicon";
  font-size: 2rem;
  color: #c0c0c2;
}

[data-bs-theme="dark"] .blockquote::before {
  color: #48484d;
}

.simplebar-track {
  transition: opacity 0.25s ease-in-out;
  background-color: var(--bs-secondary-bg);
  opacity: 0;
}

.simplebar-track .simplebar-scrollbar {
  border-radius: 0.1875rem;
  background-color: var(--bs-gray-500);
}

.simplebar-track .simplebar-scrollbar::before {
  display: none;
}

.simplebar-track.simplebar-vertical {
  width: 0.375rem !important;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar {
  right: 0;
  width: 0.375rem;
}

.simplebar-track.simplebar-horizontal {
  height: 0.375rem !important;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  top: 0;
  height: 0.375rem !important;
}

[data-simplebar] {
  overflow-y: auto;
}

[data-simplebar]::-webkit-scrollbar {
  width: 0.375rem;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
}

[data-simplebar]::-webkit-scrollbar-thumb {
  background-color: var(--bs-gray-500);
  border-radius: 0.1875rem;
}

[data-simplebar]:hover .simplebar-track {
  opacity: 1;
}

[data-simplebar]:not(.offcanvas-body) {
  padding-right: 1rem !important;
}

[data-simplebar-auto-hide="false"] .simplebar-track {
  opacity: 1;
}

.bg-dark .simplebar-track,
.bg-darker .simplebar-track,
.dropdown-menu-dark .simplebar-track {
  background-color: rgba(0, 0, 0, 0);
}

.bg-dark .simplebar-track .simplebar-scrollbar,
.bg-darker .simplebar-track .simplebar-scrollbar,
.dropdown-menu-dark .simplebar-track .simplebar-scrollbar {
  background-color: #404046;
}

.mobile-toolbar {
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: var(--bs-border-width) solid var(--bs-border-color);
  background-color: var(--bs-secondary-bg);
  box-shadow: 0 -6px 14px 0 rgba(0, 0, 0, 0.08);
  z-index: 10;
}

.mobile-toolbar-item {
  flex-grow: 1;
  padding: 0.5rem;
  text-align: center;
}

.mobile-toolbar-item .badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.75rem;
}

.mobile-toolbar-item:not(:last-child) {
  border-right: var(--bs-border-width) var(--bs-border-color);
}

.mobile-toolbar-icon {
  display: block;
  color: #359;
  font-size: 1.25rem;
}

.mobile-toolbar-icon>i,
.mobile-toolbar-icon>.badge {
  vertical-align: middle;
}

.mobile-toolbar-label {
  display: block;
  color: var(--bs-primary);
  font-size: 0.6875rem;
}

@media (min-width: 992px) {
  .mobile-toolbar {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .footer {
    padding-bottom: 4rem;
  }
}

.card-product {
  border: 0;
  transition: all 0.2s ease-in-out;
}

.card-product .product-badges,
.card-product .product-buttons {
  position: absolute;
  top: 1rem;
  right: auto;
  left: 1rem;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 0.5rem;
  z-index: 3;
}

.card-product .product-buttons {
  left: auto;
  right: 1rem;
  align-items: flex-end;
}

.card-product .product-buttons .btn-product {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: #313136;
  background-color: var(--bs-white);
  border: var(--bs-border-width) solid #d5d5d8;
  border-radius: var(--bs-border-radius-pill);
  transition: all 0.2s ease-in-out;
}

.card-product .product-buttons .btn-product:hover {
  color: var(--bs-primary);
}

.card-product .product-buttons .btn-product.btn-wishlist.active {
  color: var(--bs-primary);
}

.card-product .product-buttons .btn-product.btn-compare,
.card-product .product-buttons .btn-product.btn-view {
  opacity: 1;
  visibility: visible;
}

.card-product .card-body {
  position: relative;
  background-color: var(--bs-body-bg);
  z-index: 2;
}

.card-product .product-addon {
  position: static;
  left: 0;
  top: 100%;
  width: 100%;
  margin-top: -1rem;
  transition: all 0.2s ease-in-out;
  border-radius: var(--bs-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: var(--bs-body-bg);
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

@media (min-width: 576px) {
  .card-product {
    padding-bottom: 0;
  }
}

@media (any-hover: hover) and (min-width: 992px) {

  .card-product .product-buttons .btn-product.btn-compare,
  .card-product .product-buttons .btn-product.btn-view {
    opacity: 0;
    visibility: hidden;
  }

  .card-product .product-addon {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  .card-product:hover {
    box-shadow: var(--bs-box-shadow-lg);
    z-index: 10;
  }

  .card-product:hover .product-buttons .btn-product.btn-compare,
  .card-product:hover .product-buttons .btn-product.btn-view {
    opacity: 1;
    visibility: visible;
  }

  .card-product:hover .product-addon {
    opacity: 1;
    visibility: visible;
    box-shadow: var(--bs-box-shadow-lg);
  }
}

[data-bs-theme="dark"] .card-product {
  background-color: #252529;
}

[data-bs-theme="dark"] .card-product .card-body {
  background-color: #252529;
}

[data-bs-theme="dark"] .card-product .product-addon {
  background-color: #252529;
}

.product-list .product-list-img {
  width: 16rem;
  border-radius: var(--bs-border-radius);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .product-list .product-list-img {
    border-radius: var(--bs-border-radius);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
  }
}

[data-bs-theme="dark"] .product-list .product-buttons .btn-product {
  color: var(--bs-secondary-color);
  background-color: var(--bs-secondary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
}

[data-bs-theme="dark"] .product-list .product-buttons .btn-product:hover {
  color: var(--bs-primary);
}

[data-bs-theme="dark"] .product-list .product-buttons .btn-product.btn-wishlist.active {
  color: var(--bs-primary);
}

[data-bs-theme="dark"] .product-list .product-buttons .btn-product.btn-compare,
[data-bs-theme="dark"] .product-list .product-buttons .btn-product.btn-view {
  opacity: 1;
  visibility: visible;
}

.product-title {
  margin-bottom: 0.25rem;
  font-size: 0.9375rem;
}

.product-title>a {
  color: var(--bs-accent);
}

.product-title>a:hover {
  color: var(--bs-primary);
}

.star-rating {
  position: relative;
  display: inline-block;
  font-family: "remixicon";
  font-size: 0.8125rem;
  white-space: pre;
}

.star-rating::before,
.star-rating::after {
  content: "ï††ï††ï††ï††ï††";
  display: block;
}

.star-rating::before {
  color: var(--bs-border-color);
}

.star-rating::after {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--bs-primary);
  overflow: hidden;
}

.star-rating.star-0::after {
  width: 0%;
}

.star-rating.star-1::after {
  width: 10%;
}

.star-rating.star-2::after {
  width: 20%;
}

.star-rating.star-3::after {
  width: 30%;
}

.star-rating.star-4::after {
  width: 40%;
}

.star-rating.star-5::after {
  width: 50%;
}

.star-rating.star-6::after {
  width: 60%;
}

.star-rating.star-7::after {
  width: 70%;
}

.star-rating.star-8::after {
  width: 80%;
}

.star-rating.star-9::after {
  width: 90%;
}

.star-rating.star-10::after {
  width: 100%;
}

.swiper-gallery {
  display: flex;
  flex-direction: column;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.swiper-gallery-body {
  width: 100%;
}

.swiper-gallery-image,
.swiper-gallery-thumb-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: var(--bs-secondary-color);
  background-color: var(--bs-body-bg);
}

.swiper-gallery-image img,
.swiper-gallery-thumb-image img {
  height: 100%;
  max-width: 100%;
  text-align: center;
  -o-object-fit: cover;
  object-fit: cover;
}

.product-gallery {
  width: 100%;
  height: 100%;
  max-height: 28.75rem;
}

.product-gallery-thumbs {
  height: 5.25rem;
  width: 100%;
  padding: 0.625rem 0;
  flex-shrink: 0;
  cursor: pointer;
}

.swiper-gallery-thumb-image {
  border: 2px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.product-gallery-thumbs .swiper-slide {
  opacity: 0.6;
  transition: opacity 0.25s ease-in-out;
}

.product-gallery-thumbs .swiper-slide:hover,
.product-gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.product-gallery-thumbs .swiper-slide-thumb-active>.swiper-gallery-thumb-image {
  border: 2px solid var(--bs-primary);
}

.products-swiper-body {
  width: 100%;
  height: auto;
}

.products-swiper-body .swiper-slide {
  height: auto;
}

.recently-swiper-body {
  width: 100%;
  height: auto;
}

.recently-swiper-body .swiper-slide {
  height: auto;
}

.brand-swiper-body {
  width: 100%;
  height: auto;
  overflow: hidden;
}

@media (min-width: 768px) {
  .blog-list {
    display: flex;
  }

  .blog-list .blog-column-start {
    width: 35%;
    padding-right: 0.75rem;
  }

  .blog-list .blog-column-end {
    width: 65%;
    padding-left: 0.75rem;
  }
}

.avatar {
  display: inline-block;
  width: 3.625rem;
  height: 3.625rem;
  margin-right: 1rem;
  padding: 0.125rem;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius-pill);
}

.avatar>img {
  border-radius: var(--bs-border-radius-pill);
  max-width: 100%;
  height: auto;
}

.blog-article-title>a {
  color: var(--bs-accent);
  line-height: 1.3;
}

.blog-article-title>a:hover {
  color: var(--bs-primary);
}

.blog-article-meta-link {
  font-size: 0.9375rem;
  color: var(--bs-secondary-color);
}

.blog-article-meta-link:hover {
  color: var(--bs-body-color);
}

.blog-article-thumb {
  display: block;
  border-radius: var(--bs-border-radius-lg);
  overflow: hidden;
}

.blog-article-thumb>img {
  display: block;
  width: 100%;
}

.widget-title {
  margin-bottom: 1.125rem;
  font-size: 1rem;
  font-weight: 500;
}

.widget-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget-list[data-simplebar] {
  overflow-x: hidden;
}

.widget-list-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.widget-list-link {
  display: block;
  color: var(--bs-secondary-color);
  font-size: 0.9375rem;
  font-weight: 400;
}

.widget-list-link:hover {
  color: var(--bs-primary);
}

.active>.widget-list-link {
  color: var(--bs-primary);
}

.widget-product-title {
  font-size: 0.9375rem;
  font-weight: 400;
}

.widget-product-title>a {
  color: var(--bs-emphasis-color);
}

.widget-product-title:hover>a {
  color: var(--bs-primary);
}

.widget-product-meta {
  font-size: 0.875rem;
}

.tagcloud a {
  display: inline-block;
  padding: 0.5rem 0.75rem;
  margin: 0.25rem 0.25rem 0.25rem 0;
  transition: all 0.25s;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.25rem;
  color: var(--bs-secondary-color);
  background-color: var(--bs-tertiary-bg);
}

.tagcloud a:hover {
  border-color: var(--bs-border-color);
  color: var(--bs-white);
  background-color: var(--bs-primary);
}

.widget-categories .accordion-item {
  border: 0;
  border-radius: 0;
}

.widget-categories .accordion-body {
  padding: 0.5rem 0;
}

.widget-categories .accordion-button {
  padding: 0.5rem 0;
  font-size: 0.9375rem;
  font-weight: normal;
  color: var(--bs-primary);
  box-shadow: none;
}

.widget-categories .accordion-button::after {
  content: "î©Ž";
  width: 1.375rem;
  height: 1.375rem;
  padding-top: 0.0625rem;
  font-family: "remixicon";
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  background-image: none;
}

.widget-categories .accordion-button.collapsed {
  color: var(--bs-secondary-color);
}

.widget-categories .accordion-button:hover {
  color: var(--bs-primary);
}

.widget-categories .widget-list-item {
  margin-bottom: 0.375rem;
  padding-left: 0.75rem;
}

.widget-categories .widget-list:not([data-simplebar]) .widget-list-item {
  padding-right: 0;
}

.widget-categories .widget-list:not([data-simplebar]) .widget-list-item .widget-list-item[data-simplebar] {
  padding-right: 1rem;
}

.widget-categories .widget-list:not([data-simplebar]) .widget-list-item .widget-list:not([data-simplebar]) {
  border-right: 0;
}

.range-slider {
  height: 0.1875rem;
  margin: 3.5rem 0 2rem 0;
  border: 0;
  background-color: var(--bs-secondary-bg);
  box-shadow: none;
}

.range-slider .noUi-connect {
  background-color: var(--bs-accent);
}

.range-slider .noUi-handle {
  top: 50%;
  right: -0.6875rem;
  transform: translateY(1px);
  width: 1.375rem;
  height: 1.375rem;
  margin-top: -0.6875rem;
  border: 2px solid var(--bs-accent);
  border-radius: 50rem;
  background-color: var(--bs-body-bg);
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.2);
}

.range-slider .noUi-handle::before,
.range-slider .noUi-handle::after {
  display: none;
}

.range-slider .noUi-handle:focus {
  outline: none;
}

.range-slider .noUi-tooltip {
  margin-bottom: 0.25rem;
  padding: 0.25rem 0.5rem;
  border: 0;
  border-radius: var(--bs-border-radius);
  font-size: 0.75rem;
  color: var(--bs-white);
  background-color: var(--bs-primary);
}

[data-bs-theme="dark"] .range-slider .noUi-handle {
  background-color: #313136;
}

.steps {
  display: flex;
}

.step-item {
  flex-basis: 0;
  flex-grow: 1;
  transition: color 0.25s ease-in-out;
  text-align: center;
  text-decoration: none !important;
  padding-top: 1rem;
}

.step-progress {
  position: relative;
  height: 0.25rem;
  margin-bottom: 1rem;
}

.step-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-size: 0.9375rem;
  line-height: 1;
}

.step-label {
  margin-top: 1.5rem;
}

@media (max-width: 575.98px) {
  .step-label {
    display: none;
  }
}

.step-item {
  color: var(--bs-secondary-color);
}

.step-icon,
.step-progress {
  color: var(--bs-secondary-color);
  background-color: var(--bs-border-color);
}

.step-icon {
  border: 2px solid var(--bs-body-bg);
  box-shadow: 0 0 0 3px var(--bs-border-color);
}

.step-item:hover {
  color: var(--bs-secondary-color);
}

.step-item.active.current {
  color: var(--bs-body-color);
  pointer-events: none;
}

.step-item.active .step-icon,
.step-item.active .step-progress {
  color: var(--bs-white);
  background-color: var(--bs-primary);
}

.step-item.active .step-icon {
  box-shadow: 0 0 0 3px var(--bs-primary);
}

.track-steps {
  display: flex;
  flex-direction: column;
}

.track-step-item {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  min-height: 5rem;
  margin-left: 1rem;
}

.track-step-progress {
  position: relative;
  height: 100%;
  width: 0.25rem;
}

.track-step-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  font-size: 1.375rem;
}

.track-step-label {
  margin: auto 2rem;
}

@media (min-width: 576px) {
  .track-steps {
    flex-direction: row;
    margin-top: 1rem;
  }

  .track-step-item {
    display: flex;
    flex-direction: column;
    min-width: 120px;
    margin: 0;
  }

  .track-step-progress {
    height: 0.25rem;
    width: 100%;
  }

  .track-step-label {
    margin: 2rem 0 0 0;
    text-align: center;
  }
}

.track-steps .track-step-item {
  color: var(--bs-body-color);
}

.track-steps .track-step-icon,
.track-steps .track-step-progress {
  color: var(--bs-body-color);
  background-color: var(--bs-border-color);
}

.track-steps .track-step-item.active.current {
  color: var(--bs-white);
}

.track-steps .track-step-item.active .track-step-icon,
.track-steps .track-step-item.active .track-step-progress {
  color: var(--bs-white);
  background-color: var(--bs-success);
}

.track-steps .track-step-item.current .track-step-icon,
.track-steps .track-step-item.current .track-step-progress {
  background-color: #2e9762;
}

.testimonial-swiper-body {
  width: 100%;
  height: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.testimonial-swiper-body .swiper-slide {
  height: auto;
}

.display-404 {
  color: rgba(255, 136, 0, 0.09);
  -webkit-text-stroke: 1px #f80;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

html,
body {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  overflow-x: clip;
}

.wrapper .page {
  flex: 1 1 auto;
}

.navigation .navbar-middle {
  background-color: var(--bs-accent);
}

.navigation .navbar-nav .nav-item {
  margin-bottom: 0.625rem;
  border-radius: var(--bs-border-radius);
}

.navigation .navbar-nav .nav-item>.dropdown-menu>li>.dropdown-item {
  font-weight: 500;
}

.navigation .navbar-nav .nav-item>.dropdown-menu>li>.dropdown-item>* {
  font-weight: 400;
}

.navigation .navbar-nav .nav-link {
  padding: 0.625rem 1.125rem;
  font-weight: 500;
}

.navigation .navbar-nav .dropdown-menu {
  min-width: 12rem;
  padding-top: 0;
  box-shadow: none;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.navigation .navbar-nav .dropdown-menu .dropdown-menu {
  width: calc(100% - (1rem * 2));
  margin: 0 1rem;
}

.navigation .mega-nav {
  position: relative;
}

.navigation .mega-nav::after {
  content: "";
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: var(--bs-border-width);
  height: 1.25rem;
}

.navigation .dropdown-column {
  width: 100%;
  max-width: 15rem;
}

.navigation .mega-nav .dropdown-menu>.mega-dropdown {
  position: static;
}

.navigation .mega-nav .dropdown-menu>.dropdown>a {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.navigation .mega-nav .dropdown-menu>.dropdown:not(:last-child)>a {
  border-bottom: var(--bs-border-width) solid var(--bs-border-color-translucent);
}

.navigation .navbar-actions {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.navigation .navbar-actions>*:not(:last-child) {
  margin-right: 0.5rem;
}

.navigation .navbar-actions-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 2.75rem;
  border-radius: 0.375rem;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
  transition: background-color 0.25s ease-in-out;
}

.navigation .navbar-actions-item .badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
}

.navigation .navbar-actions-icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  .navigation .navbar-expand-sm .navbar-nav .nav-item {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0);
  }

  .navigation .navbar-expand-sm .navbar-nav .nav-item>.dropdown-menu>li>.dropdown-item {
    font-weight: normal;
  }

  .navigation .navbar-expand-sm .navbar-nav .nav-link {
    font-weight: normal;
  }

  .navigation .navbar-expand-sm .navbar-nav .nav-link.dropdown-toggle::after {
    display: none;
  }

  .navigation .navbar-expand-sm .mega-nav::after {
    display: block;
  }

  .navigation .navbar-expand-sm .mega-nav .dropdown-menu>.mega-dropdown .dropdown-menu {
    top: 0.375rem;
    min-height: 100%;
    min-height: calc(100% + 0.25rem);
    animation: none !important;
  }

  .navigation .navbar-expand-sm .dropdown-menu {
    margin-top: 0;
    padding-top: 0.5rem;
    border: var(--bs-border-width) solid var(--bs-border-color-translucent);
  }

  .navigation .navbar-expand-sm .dropdown-menu.dropdown-menu-end {
    left: auto;
    right: 0;
  }

  .navigation .navbar-expand-sm .dropdown-menu.show {
    display: none;
  }

  .navigation .navbar-expand-sm .dropdown-menu>.dropdown>.dropdown-toggle {
    position: relative;
    padding-right: 2.8125rem;
  }

  .navigation .navbar-expand-sm .dropdown-menu>.dropdown>.dropdown-toggle::after {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: "remixicon";
    font-size: 1.5rem;
    width: 0.625rem;
    height: 0.625rem;
    margin-left: 0.255em;
    color: inherit;
    content: "î©”";
  }

  .navigation .navbar-expand-sm .dropdown-menu>.dropdown>.dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .navigation .navbar-expand-sm .dropdown-menu>.dropdown>.dropdown-toggle::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
  }

  .navigation .navbar-expand-sm .dropdown-menu>.dropdown .dropdown-menu {
    display: none;
    top: 0;
    right: auto;
    left: 100%;
    width: auto;
    margin-top: -0.5rem;
    margin-right: 0;
    margin-left: -0.25rem;
  }

  .navigation .navbar-expand-sm .dropdown-menu>.dropdown:hover>.dropdown-menu {
    animation: fade-in 0.25s ease-in-out;
  }

  .navigation .navbar-expand-sm .dropdown-menu:not(.dropdown-menu-dark) {
    background-color: var(--bs-body-bg);
    box-shadow:
      0 1px 2px 0 rgba(0, 0, 0, 0.08),
      0 2px 6px 0 rgba(0, 0, 0, 0.06);
  }

  .navigation .navbar-expand-sm .dropdown-menu:not(.dropdown-menu-dark) .dropdown-menu {
    background-color: var(--bs-body-bg);
  }

  .navigation .navbar-expand-sm .dropdown:hover>.dropdown-menu {
    display: block;
    animation: slide-down 0.25s ease-in-out;
  }

  .navigation .navbar-expand-sm .dropdown-column {
    width: 15rem;
  }
}

@media (min-width: 768px) {
  .navigation .navbar-expand-md .navbar-nav .nav-item {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0);
  }

  .navigation .navbar-expand-md .navbar-nav .nav-item>.dropdown-menu>li>.dropdown-item {
    font-weight: normal;
  }

  .navigation .navbar-expand-md .navbar-nav .nav-link {
    font-weight: normal;
  }

  .navigation .navbar-expand-md .navbar-nav .nav-link.dropdown-toggle::after {
    display: none;
  }

  .navigation .navbar-expand-md .mega-nav::after {
    display: block;
  }

  .navigation .navbar-expand-md .mega-nav .dropdown-menu>.mega-dropdown .dropdown-menu {
    top: 0.375rem;
    min-height: 100%;
    min-height: calc(100% + 0.25rem);
    animation: none !important;
  }

  .navigation .navbar-expand-md .dropdown-menu {
    margin-top: 0;
    padding-top: 0.5rem;
    border: var(--bs-border-width) solid var(--bs-border-color-translucent);
  }

  .navigation .navbar-expand-md .dropdown-menu.dropdown-menu-end {
    left: auto;
    right: 0;
  }

  .navigation .navbar-expand-md .dropdown-menu.show {
    display: none;
  }

  .navigation .navbar-expand-md .dropdown-menu>.dropdown>.dropdown-toggle {
    position: relative;
    padding-right: 2.8125rem;
  }

  .navigation .navbar-expand-md .dropdown-menu>.dropdown>.dropdown-toggle::after {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: "remixicon";
    font-size: 1.5rem;
    width: 0.625rem;
    height: 0.625rem;
    margin-left: 0.255em;
    color: inherit;
    content: "î©”";
  }

  .navigation .navbar-expand-md .dropdown-menu>.dropdown>.dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .navigation .navbar-expand-md .dropdown-menu>.dropdown>.dropdown-toggle::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
  }

  .navigation .navbar-expand-md .dropdown-menu>.dropdown .dropdown-menu {
    display: none;
    top: 0;
    right: auto;
    left: 100%;
    width: auto;
    margin-top: -0.5rem;
    margin-right: 0;
    margin-left: -0.25rem;
  }

  .navigation .navbar-expand-md .dropdown-menu>.dropdown:hover>.dropdown-menu {
    animation: fade-in 0.25s ease-in-out;
  }

  .navigation .navbar-expand-md .dropdown-menu:not(.dropdown-menu-dark) {
    background-color: var(--bs-body-bg);
    box-shadow:
      0 1px 2px 0 rgba(0, 0, 0, 0.08),
      0 2px 6px 0 rgba(0, 0, 0, 0.06);
  }

  .navigation .navbar-expand-md .dropdown-menu:not(.dropdown-menu-dark) .dropdown-menu {
    background-color: var(--bs-body-bg);
  }

  .navigation .navbar-expand-md .dropdown:hover>.dropdown-menu {
    display: block;
    animation: slide-down 0.25s ease-in-out;
  }

  .navigation .navbar-expand-md .dropdown-column {
    width: 15rem;
  }
}

@media (min-width: 992px) {
  .navigation .navbar-expand-lg .navbar-nav .nav-item {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0);
  }

  .navigation .navbar-expand-lg .navbar-nav .nav-item>.dropdown-menu>li>.dropdown-item {
    font-weight: normal;
  }

  .navigation .navbar-expand-lg .navbar-nav .nav-link {
    font-weight: normal;
  }

  .navigation .navbar-expand-lg .navbar-nav .nav-link.dropdown-toggle::after {
    display: none;
  }

  .navigation .navbar-expand-lg .mega-nav::after {
    display: block;
  }

  .navigation .navbar-expand-lg .mega-nav .dropdown-menu>.mega-dropdown .dropdown-menu {
    top: 0.375rem;
    min-height: 100%;
    min-height: calc(100% + 0.25rem);
    animation: none !important;
  }

  .navigation .navbar-expand-lg .dropdown-menu {
    margin-top: 0;
    padding-top: 0.5rem;
    border: var(--bs-border-width) solid var(--bs-border-color-translucent);
  }

  .navigation .navbar-expand-lg .dropdown-menu.dropdown-menu-end {
    left: auto;
    right: 0;
  }

  .navigation .navbar-expand-lg .dropdown-menu.show {
    display: none;
  }

  .navigation .navbar-expand-lg .dropdown-menu>.dropdown>.dropdown-toggle {
    position: relative;
    padding-right: 2.8125rem;
  }

  .navigation .navbar-expand-lg .dropdown-menu>.dropdown>.dropdown-toggle::after {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: "remixicon";
    font-size: 1.5rem;
    width: 0.625rem;
    height: 0.625rem;
    margin-left: 0.255em;
    color: inherit;
  }

  .navigation .navbar-expand-lg .dropdown-menu>.dropdown>.dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .navigation .navbar-expand-lg .dropdown-menu>.dropdown>.dropdown-toggle::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
  }

  .navigation .navbar-expand-lg .dropdown-menu>.dropdown .dropdown-menu {
    display: none;
    top: 0;
    right: auto;
    left: 100%;
    width: auto;
    margin-top: -0.5rem;
    margin-right: 0;
    margin-left: -0.25rem;
  }

  .navigation .navbar-expand-lg .dropdown-menu>.dropdown:hover>.dropdown-menu {
    animation: fade-in 0.25s ease-in-out;
  }

  .navigation .navbar-expand-lg .dropdown-menu:not(.dropdown-menu-dark) {
    background-color: var(--bs-body-bg);
    box-shadow:
      0 1px 2px 0 rgba(0, 0, 0, 0.08),
      0 2px 6px 0 rgba(0, 0, 0, 0.06);
  }

  .navigation .navbar-expand-lg .dropdown-menu:not(.dropdown-menu-dark) .dropdown-menu {
    background-color: var(--bs-body-bg);
  }

  .navigation .navbar-expand-lg .dropdown:hover>.dropdown-menu {
    display: block;
    animation: slide-down 0.25s ease-in-out;
  }

  .navigation .navbar-expand-lg .dropdown-column {
    width: 15rem;
  }
}

@media (min-width: 1200px) {
  .navigation .navbar-expand-xl .navbar-nav .nav-item {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0);
  }

  .navigation .navbar-expand-xl .navbar-nav .nav-item>.dropdown-menu>li>.dropdown-item {
    font-weight: normal;
  }

  .navigation .navbar-expand-xl .navbar-nav .nav-link {
    font-weight: normal;
  }

  .navigation .navbar-expand-xl .navbar-nav .nav-link.dropdown-toggle::after {
    display: none;
  }

  .navigation .navbar-expand-xl .mega-nav::after {
    display: block;
  }

  .navigation .navbar-expand-xl .mega-nav .dropdown-menu>.mega-dropdown .dropdown-menu {
    top: 0.375rem;
    min-height: 100%;
    min-height: calc(100% + 0.25rem);
    animation: none !important;
  }

  .navigation .navbar-expand-xl .dropdown-menu {
    margin-top: 0;
    padding-top: 0.5rem;
    border: var(--bs-border-width) solid var(--bs-border-color-translucent);
  }

  .navigation .navbar-expand-xl .dropdown-menu.dropdown-menu-end {
    left: auto;
    right: 0;
  }

  .navigation .navbar-expand-xl .dropdown-menu.show {
    display: none;
  }

  .navigation .navbar-expand-xl .dropdown-menu>.dropdown>.dropdown-toggle {
    position: relative;
    padding-right: 2.8125rem;
  }

  .navigation .navbar-expand-xl .dropdown-menu>.dropdown>.dropdown-toggle::after {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: "remixicon";
    font-size: 1.5rem;
    width: 0.625rem;
    height: 0.625rem;
    margin-left: 0.255em;
    color: inherit;
    content: "î©”";
  }

  .navigation .navbar-expand-xl .dropdown-menu>.dropdown>.dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .navigation .navbar-expand-xl .dropdown-menu>.dropdown>.dropdown-toggle::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
  }

  .navigation .navbar-expand-xl .dropdown-menu>.dropdown .dropdown-menu {
    display: none;
    top: 0;
    right: auto;
    left: 100%;
    width: auto;
    margin-top: -0.5rem;
    margin-right: 0;
    margin-left: -0.25rem;
  }

  .navigation .navbar-expand-xl .dropdown-menu>.dropdown:hover>.dropdown-menu {
    animation: fade-in 0.25s ease-in-out;
  }

  .navigation .navbar-expand-xl .dropdown-menu:not(.dropdown-menu-dark) {
    background-color: var(--bs-body-bg);
    box-shadow:
      0 1px 2px 0 rgba(0, 0, 0, 0.08),
      0 2px 6px 0 rgba(0, 0, 0, 0.06);
  }

  .navigation .navbar-expand-xl .dropdown-menu:not(.dropdown-menu-dark) .dropdown-menu {
    background-color: var(--bs-body-bg);
  }

  .navigation .navbar-expand-xl .dropdown:hover>.dropdown-menu {
    display: block;
    animation: slide-down 0.25s ease-in-out;
  }

  .navigation .navbar-expand-xl .dropdown-column {
    width: 15rem;
  }
}

@media (min-width: 1400px) {
  .navigation .navbar-expand-xxl .navbar-nav .nav-item {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0);
  }

  .navigation .navbar-expand-xxl .navbar-nav .nav-item>.dropdown-menu>li>.dropdown-item {
    font-weight: normal;
  }

  .navigation .navbar-expand-xxl .navbar-nav .nav-link {
    font-weight: normal;
  }

  .navigation .navbar-expand-xxl .navbar-nav .nav-link.dropdown-toggle::after {
    display: none;
  }

  .navigation .navbar-expand-xxl .mega-nav::after {
    display: block;
  }

  .navigation .navbar-expand-xxl .mega-nav .dropdown-menu>.mega-dropdown .dropdown-menu {
    top: 0.375rem;
    min-height: 100%;
    min-height: calc(100% + 0.25rem);
    animation: none !important;
  }

  .navigation .navbar-expand-xxl .dropdown-menu {
    margin-top: 0;
    padding-top: 0.5rem;
    border: var(--bs-border-width) solid var(--bs-border-color-translucent);
  }

  .navigation .navbar-expand-xxl .dropdown-menu.dropdown-menu-end {
    left: auto;
    right: 0;
  }

  .navigation .navbar-expand-xxl .dropdown-menu.show {
    display: none;
  }

  .navigation .navbar-expand-xxl .dropdown-menu>.dropdown>.dropdown-toggle {
    position: relative;
    padding-right: 2.8125rem;
  }

  .navigation .navbar-expand-xxl .dropdown-menu>.dropdown>.dropdown-toggle::after {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: "remixicon";
    font-size: 1.5rem;
    width: 0.625rem;
    height: 0.625rem;
    margin-left: 0.255em;
    color: inherit;
    content: "î©”";
  }

  .navigation .navbar-expand-xxl .dropdown-menu>.dropdown>.dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .navigation .navbar-expand-xxl .dropdown-menu>.dropdown>.dropdown-toggle::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
  }

  .navigation .navbar-expand-xxl .dropdown-menu>.dropdown .dropdown-menu {
    display: none;
    top: 0;
    right: auto;
    left: 100%;
    width: auto;
    margin-top: -0.5rem;
    margin-right: 0;
    margin-left: -0.25rem;
  }

  .navigation .navbar-expand-xxl .dropdown-menu>.dropdown:hover>.dropdown-menu {
    animation: fade-in 0.25s ease-in-out;
  }

  .navigation .navbar-expand-xxl .dropdown-menu:not(.dropdown-menu-dark) {
    background-color: var(--bs-body-bg);
    box-shadow:
      0 1px 2px 0 rgba(0, 0, 0, 0.08),
      0 2px 6px 0 rgba(0, 0, 0, 0.06);
  }

  .navigation .navbar-expand-xxl .dropdown-menu:not(.dropdown-menu-dark) .dropdown-menu {
    background-color: var(--bs-body-bg);
  }

  .navigation .navbar-expand-xxl .dropdown:hover>.dropdown-menu {
    display: block;
    animation: slide-down 0.25s ease-in-out;
  }

  .navigation .navbar-expand-xxl .dropdown-column {
    width: 15rem;
  }
}

.navigation .navbar-expand .navbar-nav .nav-item {
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
}

.navigation .navbar-expand .navbar-nav .nav-item>.dropdown-menu>li>.dropdown-item {
  font-weight: normal;
}

.navigation .navbar-expand .navbar-nav .nav-link {
  font-weight: normal;
}

.navigation .navbar-expand .navbar-nav .nav-link.dropdown-toggle::after {
  display: none;
}

.navigation .navbar-expand .mega-nav::after {
  display: block;
}

.navigation .navbar-expand .mega-nav .dropdown-menu>.mega-dropdown .dropdown-menu {
  top: 0.375rem;
  min-height: 100%;
  min-height: calc(100% + 0.25rem);
  animation: none !important;
}

.navigation .navbar-expand .dropdown-menu {
  margin-top: 0;
  padding-top: 0.5rem;
  border: var(--bs-border-width) solid var(--bs-border-color-translucent);
}

.navigation .navbar-expand .dropdown-menu.dropdown-menu-end {
  left: auto;
  right: 0;
}

.navigation .navbar-expand .dropdown-menu.show {
  display: none;
}

.navigation .navbar-expand .dropdown-menu>.dropdown>.dropdown-toggle {
  position: relative;
  padding-right: 2.8125rem;
}

.navigation .navbar-expand .dropdown-menu>.dropdown>.dropdown-toggle::after {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "remixicon";
  font-size: 1.5rem;
  width: 0.625rem;
  height: 0.625rem;
  margin-left: 0.255em;
  color: inherit;
  content: "î©”";
}

.navigation .navbar-expand .dropdown-menu>.dropdown>.dropdown-toggle:empty::after {
  margin-left: 0;
}

.navigation .navbar-expand .dropdown-menu>.dropdown>.dropdown-toggle::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
}

.navigation .navbar-expand .dropdown-menu>.dropdown .dropdown-menu {
  display: none;
  top: 0;
  right: auto;
  left: 100%;
  width: auto;
  margin-top: -0.5rem;
  margin-right: 0;
  margin-left: -0.25rem;
}

.navigation .navbar-expand .dropdown-menu>.dropdown:hover>.dropdown-menu {
  animation: fade-in 0.25s ease-in-out;
}

.navigation .navbar-expand .dropdown-menu:not(.dropdown-menu-dark) {
  background-color: var(--bs-body-bg);
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.08),
    0 2px 6px 0 rgba(0, 0, 0, 0.06);
}

.navigation .navbar-expand .dropdown-menu:not(.dropdown-menu-dark) .dropdown-menu {
  background-color: var(--bs-body-bg);
}

.navigation .navbar-expand .dropdown:hover>.dropdown-menu {
  display: block;
  animation: slide-down 0.25s ease-in-out;
}

.navigation .navbar-expand .dropdown-column {
  width: 15rem;
}

.navigation .navbar-stuck-toggle {
  display: none;
}

@media (min-width: 992px) {
  .navigation .navbar-sticky.navbar-stuck {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    animation: slide-down 0.25s;
    box-shadow: var(--bs-box-shadow-sm);
    z-index: 1030;
  }

  .navigation .navbar-sticky.navbar-stuck .navbar-stuck-toggle {
    display: flex;
  }

  .navigation .navbar-sticky.navbar-stuck .navbar-stuck-menu {
    display: none;
  }

  .navigation .navbar-sticky.navbar-stuck .navbar-stuck-menu.show {
    display: block;
  }
}

.navigation .nav-link>i {
  color: var(--bs-gray-500);
}

.navigation .nav-item {
  background-color: var(--bs-tertiary-bg);
}

.navigation .nav-item:hover .nav-link:not(.disabled),
.navigation .nav-item:hover .nav-link:not(.disabled)>i {
  color: var(--bs-primary);
}

.navigation .nav-item.active .nav-link:not(.disabled)>i,
.navigation .nav-item.show .nav-link:not(.disabled)>i,
.navigation .nav-item.dropdown .nav-link:focus:not(.disabled)>i,
.navigation .nav-item.active .nav-link:not(.disabled),
.navigation .nav-item.show .nav-link:not(.disabled),
.navigation .nav-item.dropdown .nav-link:focus:not(.disabled) {
  color: var(--bs-primary);
}

.navigation .mega-nav::after {
  background-color: var(--bs-border-color);
}

.navigation .topbar {
  --bs-topbar-height: 2rem;
  --bs-topbar-padding-x: 0;
  --bs-topbar-padding-y: 0.25rem;
  --bs-topbar-font-size: 0.875rem;
  --bs-topbar-icon-font-size: 1rem;
  --bs-topbar-list-space: 1.25rem;
  --bs-topbar-color: var(--bs-secondary-color);
  --bs-topbar-bg: var(--bs-tertiary-bg);
  --bs-topbar-divider-bg: var(--bs-border-color);
  --bs-topbar-link-color: var(--bs-secondary-color);
  --bs-topbar-link-hover-color: var(--bs-body-color);
  display: flex;
  align-items: center;
  min-height: var(--bs-topbar-height);
  padding: var(--bs-topbar-padding-y) var(--bs-topbar-padding-x);
  font-size: var(--bs-topbar-font-size);
  color: var(--bs-topbar-color);
  background-color: var(--bs-topbar-bg);
}

.navigation .topbar .topbar-swiper {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.navigation .topbar .topbar-link {
  color: var(--bs-topbar-link-color);
}

.navigation .topbar .topbar-link:hover {
  color: var(--bs-topbar-link-hover-color);
}

.navigation .topbar .topbar-list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.navigation .topbar .topbar-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: var(--bs-topbar-list-space);
}

.navigation .topbar .topbar-item:last-child {
  margin-right: 0;
}

.navigation .topbar .topbar-item:not(:last-child)::after {
  background-color: var(--bs-topbar-divider-bg);
}

.navigation .topbar .topbar-list.topbar-list-right {
  justify-content: flex-end;
}

.navigation .topbar .topbar-item:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  width: 1px;
  height: 10px;
}

.navigation .topbar .topbar-list .dropdown-menu {
  min-width: 7.5rem;
}

.navigation .topbar .topbar-item i {
  font-size: var(--bs-topbar-icon-font-size);
}

[data-bs-theme="dark"] .navigation .navbar-middle {
  background-color: #313136;
}

[data-bs-theme="dark"] .navigation .navbar-middle .form-control,
[data-bs-theme="dark"] .navigation .navbar-middle .form-select {
  background-color: #48484d;
  border-color: #5a5a5f;
}

[data-bs-theme="dark"] .navigation .navbar-middle .form-control:focus,
[data-bs-theme="dark"] .navigation .navbar-middle .form-select:focus {
  border-color: #f80;
}

.hero-swiper {
  position: relative;
}

.hero-swiper-body {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.hero-swiper-button {
  display: none;
}

@media (min-width: 1400px) {
  .hero-swiper-button {
    position: absolute;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 52px;
    transition: color 0.25s;
  }

  .hero-swiper-button-next {
    right: -58px;
    color: var(--bs-gray-200);
  }

  .hero-swiper-button-next:hover {
    color: var(--bs-accent);
  }

  .hero-swiper-button-prev {
    left: -58px;
    color: var(--bs-gray-200);
  }

  .hero-swiper-button-prev:hover {
    color: var(--bs-accent);
  }

  [data-bs-theme="dark"] .hero-swiper-button-next {
    color: var(--bs-gray-700);
  }

  [data-bs-theme="dark"] .hero-swiper-button-next:hover {
    color: var(--bs-accent);
  }

  [data-bs-theme="dark"] .hero-swiper-button-prev {
    color: var(--bs-gray-700);
  }

  [data-bs-theme="dark"] .hero-swiper-button-prev:hover {
    color: var(--bs-accent);
  }
}

.hero-swiper-pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.hero-swiper-pagination .swiper-pagination-bullet {
  width: 1rem;
  height: 0.25rem;
  border-radius: 0;
  background-color: var(--bs-gray-300);
  opacity: 1;
}

.hero-swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--bs-accent);
}

[data-bs-theme="dark"] .hero-swiper-pagination .swiper-pagination-bullet {
  background-color: var(--bs-gray-700);
}

[data-bs-theme="dark"] .hero-swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--bs-accent);
}

.hero-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}

@media (min-width: 992px) {
  .hero-grid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
}

.hero-grid-item-md {
  grid-column: 1;
  grid-row: 1 / span 2;
}

.hero-grid-item-lg {
  grid-column: 3 / span 2;
  grid-row: 1 / span 2;
}

.card-product .product-addon {
  position: static;
  opacity: 1;
  visibility: visible;
}

.dropdown-toggle::after {
  display: inline-block;
  content: "";
  border: solid #4a4a4a;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg) translateX(-3px) translateY(-1px) scale(0.8);
}

ul.dropdown-menu .dropdown-toggle::after {
  transform: rotate(315deg) translateX(0px) translateY(-5px) scale(0.8) !important;
}

.hero-swiper span.swiper-pagination-bullet {
  width: 20px;
  border-radius: 0px;
  height: 5px;
}

.hero-swiper span.swiper-pagination-bullet {
  background-color: #00406e;
}

.hero-swiper .swiper.hero-swiper-body {
  overflow: visible;
}

.swiper.hero-swiper-body>.swiper-wrapper {
  /* overflow: visible; */
  padding-bottom: 25px !important;
}

.hero-swiper .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0px;
}

.hero-swiper .swiper-button-prev:after,
.hero-swiper .swiper-button-next:after {
  color: #00406e;
  font-size: 20px;
  -webkit-text-stroke-width: 1px;
}

.hero.hero-swiper .swiper-button-prev,
.hero.hero-swiper .swiper-button-next {
  width: 20px;
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #00406e3d;
}


.hero-swiper .swiper-button-prev:hover:after,
.hero-swiper .swiper-button-next:hover:after {
  color: #00406e;
}

.card-product:hover {
  box-shadow: none !important;
}

.card-product:hover .product-addon {
  box-shadow: none !important;
}

.hero-swiper .swiper-button-prev {
  /* transform: translateX(-60px); */
}

/* ul.dropdown-menu  .dropdown-toggle::after{
content: none;
} */
.star-rating::before,
.star-rating::after {
  content: "";
  display: block;
}

.widget-categories .accordion-button::after {
  content: "";
}

.noUi-horizontal {
  height: 5px !important;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0 !important;
}

.card-product .product-title>a {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.zoom-overlay {
  width: 36vh;
  height: 36vh;
  left: 50%;
  top: 50%;
  z-index: 9999;
  background: red;
  position: absolute;
  transform: translatex(-50%) translatey(-50%) scale(2);
}

.product-details {
  position: relative;
  overflow: visible;
  z-index: 0;
}

.navigation .navbar-middle {
  background-color: white;
}

.navigation .navbar-actions-item,
.navbar.navbar-expand-lg.navbar-stuck-menu {
  background-color: var(--bs-accent) !important;
}

.navbar.navbar-expand-lg.navbar-stuck-menu .nav-link>i,
.navbar.navbar-expand-lg.navbar-stuck-menu .navbar-nav .nav-link {
  color: white;
}

.navigation .nav-item:hover .nav-link:not(.disabled),
.navigation .nav-item:hover .nav-link:not(.disabled)>i {
  color: var(--bs-primary);
}

header.navigation>.topbar {
  border-bottom: 1px solid #d5d5d8;
  background: white;
}

.overlayshow {
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: #00000021;
  z-index: 9;
}

.bg-black {
  background-color: black !important;
}

div#navbarCollapse .bg-black {
  background-color: black !important;
  border-radius: 0.375rem;
}

.navbar-sticky.navbar-stuck>.navbar.navbar-middle.navbar-expand-lg {
  background: var(--bs-accent);
}

.whitesmoke {
  background-color: whitesmoke;
}

.card.card-product.h-100 {
  background: white;
}

main .swiper-wrapper {
  padding-bottom: 15px;
}

.hide-desk-arrow .swiper-button-prev:after,
.hide-desk-arrow .swiper-button-next:after {
  visibility: hidden !important;
}

.hide-desk-arrow:hover .swiper-button-prev:after,
.hide-desk-arrow:hover .swiper-button-next:after {
  visibility: visible !important;
}

* {
  font-family: "Urbanist", sans-serif;
}

.w-500 {
  max-width: 500px;
}

.input-group.d-none.d-lg-flex.me-5.w-500>input {
  background: #eff8fe;
}

.navigation .navbar-sticky.navbar-stuck .navbar-stuck-menu.show {
  background: #144f79 !important;
}

div#category-sidebar {
  background: white;
}

.bg-btn-new {
  background: #00406e;
  color: white !important;
}

.bg-btn-new:hover {
  background: #00406e;
  color: white !important;
}

.bg-btn-new:hover {
  background: #ff8800;
}

button.btn.search {
  background: transparent !important;
  color: #76797e;
  font-size: 20px;
  padding: 0px 10px;
  border: none;
  position: absolute;
  z-index: 99;
}

input.searchinput {
  padding-left: 38px;
  border-radius: 5px !important;
  /* background: whitesmoke !important; */
  border-color: transparent !important;
}

input.searchinput:focus {
  box-shadow: none !important;
  border-color: #76797e2b !important;
}

.searchbox {
  align-items: center;
}

.searchbox button.btn.btn-primary.close {
  position: absolute;
  color: black;
  width: 25px;
  height: 25px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 100% !important;
  background: white !important;
  z-index: 9999;
  right: 7px;
  border: none;
  box-shadow: 0px 0px 5px -2px #0a16256e;
}

.navigation .navbar-nav .nav-link {
  font-weight: 500 !important;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 5px 1.125rem;
  font-size: 14px;
}

.navigation .navbar-actions-item * {
  color: black;
}

.navigation .navbar-actions-item {
  background: white !important;
}

.translate-middle {
  transform: translate(-90%, -20%) !important;
}

.navigation .navbar-actions-icon-box {
  width: auto;
  padding: 0px 5px;
}

span.position-absolute.top-0.translate-middle.badge.rounded-pill.bg-danger {
  background-color: #ff8800 !important;
  color: white;
}

.product-price span.text-danger {
  font-weight: 600;
  font-family: sans-serif;
  color: #00406e !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.skeleton {
  height: 20px;
  border-radius: 5px;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(8%, rgba(130, 130, 130, 0.2)),
      color-stop(18%, rgba(130, 130, 130, 0.3)),
      color-stop(33%, rgba(130, 130, 130, 0.2)));
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-squares 2s infinite ease-out;
}

@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.color-options {
  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 10px;
  margin-top: 20px;
}

.color-circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: auto;
  cursor: pointer;
}

.color-options>label>span.hovercolor {
  position: absolute;
  top: 0px;
  background: white;
  left: 50%;
  z-index: 99;
  border: 1px solid;
  padding: 1px 15px;
  border-radius: 25px;
  transform: translateX(-50%);
  opacity: 0;
  transition-duration: 0.5s;
  font-size: 13px;
  white-space: nowrap;
  z-index: -1;
}

.color-options>label:hover>span.hovercolor {
  top: -35px;
  opacity: 1;
  z-index: 2;
}

.color-options>label>input:checked+.color-circle {
  border: 2px solid white;
  outline: 2px solid black;
}

.color-options>label {
  position: relative;
  text-align: center;
}

div#Overviewbx .accordion-body p {
  margin: 0px;
}

/* for none scroll animation */
html,
body {
  scroll-behavior: auto !important;
  transition: none !important;
}

.searchbox>input.searchinput {
  border: 2px solid #00406e !important;
  overflow: hidden;
  border-radius: 6px;
}

.navbar-sticky.navbar-stuck .searchbox>input.searchinput {
  background-color: white !important;
}


/* contact us css start  */
.contact_us {
  background-color: #f1f1f1;
  padding: 120px 0px;
}

.contact_inner {
  background-color: #fff;
  position: relative;
  box-shadow: 20px 22px 44px #cccc;
  border-radius: 25px;
}

.contact_field {
  padding: 60px 340px 90px 100px;
}

.right_conatct_social_icon {
  height: 100%;
}


.contact_field .form-control {
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #ccc;
}

.contact_field .form-control:focus {
  box-shadow: none;
  outline: none;
  border-bottom: 2px solid #1325e8;
}

.contact_field .form-control::placeholder {
  font-size: 16px;
  letter-spacing: 1px;
}

.contact_field .form-control {
  font-size: 16px;
  letter-spacing: 1px;
}


.contact_info_sec {
  position: absolute;
  background-color: #2d2d2d;
  right: 1px;
  top: 18%;
  height: 340px;
  width: 340px;
  padding: 40px;
  border-radius: 25px 0 0 25px;
}

.contact_info_sec h4 {
  letter-spacing: 1px;
  padding-bottom: 15px;
}

.info_single {
  margin: 30px 0px;
}

.info_single i {
  margin-right: 15px;
}

.info_single span {
  font-size: 14px;
  letter-spacing: 1px;
}

button.contact_form_submit {
  background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
  border: none;
  color: #fff;
  padding: 10px 15px;
  width: 100%;
  margin-top: 25px;
  border-radius: 35px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 2px;
}

.socil_item_inner li {
  list-style: none;
}

.socil_item_inner li a {
  color: #fff;
  margin: 0px 15px;
  font-size: 14px;
}

.socil_item_inner {
  padding-bottom: 10px;
}

.map_sec {
  padding: 50px 0px;
}

.map_inner h4,
.map_inner p {
  color: #000;
  text-align: center
}

.map_bind {
  margin-top: 50px;
  border-radius: 30px;
  overflow: hidden;
}

/* contact us css end  */






.user-dasboard nav {
  min-height: 70px;
}

.user-dasboard nav .navbar-brand .logo {
  padding: 10px 15px;
  border-radius: 8px;
}

nav .navbar-brand .logo .h2 {
  margin: 0;
}

nav .navbar-brand .h4 {
  margin-bottom: 0px;
  font-weight: 900;
}

nav .navbar-brand .fs-6 {
  font-size: 0.88rem !important;
}

nav ul li {
  padding: 0 20px;
}

.navbar-light .navbar-nav .nav-link {
  color: #333;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #4e2296;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #451296;
}

nav ul li a .cart {
  padding: 4px 6px;
  border-radius: 6px;
  position: relative;
  display: inline;
}

nav ul li a .cart::after {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff5252;
  top: -1px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

#sidebar {
  padding: 15px 0px 15px 0px;
  border-radius: 10px;
}

#sidebar .h4 {
  font-weight: 500;
  padding-left: 15px;
}

#sidebar ul {
  list-style: none;
  margin: 0;
  padding-left: 0rem;
}

#sidebar ul li {
  padding: 10px 0;
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  border-left: 5px solid transparent;
}

#sidebar ul li.active {
  border-left: 5px solid #f4be80;
  background-color: #ff8800;
  color: black;
}



#sidebar ul li a {
  display: block;
}

#sidebar ul li a .fas,
#sidebar ul li a .far {
  color: #ddd;
}

#sidebar ul li a .link {
  color: #fff;
  font-weight: 500;
}

#sidebar ul li a .link-desc {
  font-size: 0.8rem;
  color: #ddd;
}

#sidebar ul li.active * {
  color: white !important;
}

#main-content {
  padding: 30px;
  border-radius: 15px;
}

#main-content .h5,
#main-content .text-uppercase {
  font-weight: 600;
  margin-bottom: 0;
}

#main-content .h5+div {
  font-size: 0.9rem;
}

#main-content .box {
  padding: 10px;
  border-radius: 6px;
  width: 170px;
  height: 90px;
}

#main-content .box img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

#main-content .box .tag {
  font-size: 0.9rem;
  color: #000;
  font-weight: 500;
}

#main-content .box .number {
  font-size: 1.5rem;
  font-weight: 600;
}

.order {
  padding: 10px 30px;
  min-height: 150px;
}

.order .order-summary {
  height: 100%;
}

.order .blue-label {
  background-color: #aeaeeb;
  color: #0046dd;
  font-size: 0.9rem;
  padding: 0px 3px;
}

.order .green-label {
  background-color: #a8e9d0;
  color: #008357;
  font-size: 0.9rem;
  padding: 0px 3px;
}

.order .fs-8 {
  font-size: 0.85rem;
}

.order .rating img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.order .rating .fas,
.order .rating .far {
  font-size: 0.9rem;
}

.order .rating .fas {
  color: #daa520;
}

.order .status {
  font-weight: 600;
}

.order .btn.btn-primary {
  background-color: #fff;
  color: var(--bs-accent);
  border: 1px solid var(--bs-accent);
}

.order .btn.btn-primary:hover {
  background-color: var(--bs-accent);
  color: #fff;
}

.order .progressbar-track {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.order .progressbar-track .progressbar {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0rem;
}

.order .progressbar-track .progressbar li {
  font-size: 1.5rem;
  border: 1px solid #333;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: #dddddd;
  z-index: 100;
  position: relative;
}

.order .progressbar-track .progressbar li.green {
  border: 1px solid #007965;
  background-color: #d5e6e2;
}

.order .progressbar-track .progressbar li::after {
  position: absolute;
  font-size: 0.9rem;
  top: 50px;
  left: 0px;
}

#tracker {
  position: absolute;
  border-top: 1px solid #bbb;
  width: 100%;
  top: 25px;
}

#step-1::after {
  content: 'Placed';
}

#step-2::after {
  content: 'Accepted';
  left: -10px;
}

#step-3::after {
  content: 'Packed';
}

#step-4::after {
  content: 'Shipped';
}

#step-5::after {
  content: 'Delivered';
  left: -10px;
}

.bg-light-border {
  border: 2px solid #00406e;
  background: #eff8fe4a;
}

/* Backgrounds */
.bg-purple {
  background-color: #00406e;
}

.bg-light {
  background-color: #f0ecec !important;
}

.green {
  color: #007965 !important;
}



#mobile_menu_trigger {

  border: none;
  cursor: pointer;
  z-index: 2;
}

#mobile_menu_container {
  position: fixed;
  right: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  background-color: rgb(0 0 0 / 75%);
  z-index: 99;
  -webkit-transition: all 0.35s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  -moz-transition: all 0.35s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  -o-transition: all 0.35s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  transition: all 0.35s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  z-index: -99;
  visibility: hidden;
}

#mobile_menu_container.open {
  z-index: 9999;
  visibility: visible;
}

#mobile_menu_container_inner {
  width: 100%;
  max-width: 400px;
  background-color: white;
  position: absolute;
  left: -100%;
  top: 0;
  bottom: 0;
  transition: all;
  transition-duration: 0.5s;
}

#mobile_menu_container.open>div#mobile_menu_container_inner {
  left: 0px;
}

.mobile_menu_top {
  border-bottom: 1px solid #e9e9e9;
  position: relative;
  left: 0;
  top: 0;
  padding: 0px 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile_menu_top a {
  display: block;
  width: 146px;
  height: auto;
}

.mobile_menu_top img {
  display: block;
  width: 100%;
}

.mobile_menu_close {
  width: 57px;
  text-align: center;
  height: 57px;
  line-height: 57px;
  font-size: 20px;
  cursor: pointer;
}

#mobile_menu_image_links {
  width: auto;
  padding: 0 11px;
  overflow-x: auto;
  overflow-y: hidden;
}

#mobile_menu_links {
  width: 550px;
  padding: 20px 0 20px 0;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  border-bottom: 1px solid #e9e9e9;
}

#mobile_menu_links a {
  width: 16.66666666667%;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  color: #414453;
  font-size: 12px;
  text-transform: uppercase;
}

#mobile_menu_links img {
  display: block;
  height: 78px;
  width: auto;
  margin: 0 auto 10px auto;
}

#mobile_menu {
  position: absolute;
  left: 0;
  top: 55px;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fafafa;
  border-top: 1px solid #f4f4f4;
}

#mobile_menu a,
#mobile_menu span {
  display: block;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 20px;
  text-transform: capitalize;
  position: relative;
  left: 0;
  top: 0;
  border-bottom: 1px solid #f4f4f4;
  color: #414453;
  cursor: pointer;
  background-color: #fff;
}

#mobile_menu a:hover,
#mobile_menu span:hover {
  background-color: white;
  color: #a62a2a;
}

#mobile_menu i {
  position: absolute;
  right: 20px;
  top: 18px;
  color: #434447;
  font-size: 13px;
}

#mobile_menu .mobile_menu_back i {
  position: static;
  margin-right: 10px;
  font-size: 18px;
}

#mobile_menu i.static {
  position: static;
  margin-right: 10px;
}

#mobile_menu .submenu {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: #fafafa;
  left: 100%;
  z-index: 1;
  -webkit-transition: all 0.35s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  -moz-transition: all 0.35s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  -o-transition: all 0.35s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  transition: all 0.35s cubic-bezier(0.79, 0.14, 0.15, 0.86);
}

#mobile_menu .submenu.open {
  left: 0;
}

#mobile_menu .sub_sub_menu>ul {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: #fafafa;
  left: 100%;
  z-index: 2;
  -webkit-transition: all 0.35s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  -moz-transition: all 0.35s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  -o-transition: all 0.35s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  transition: all 0.35s cubic-bezier(0.79, 0.14, 0.15, 0.86);
}

#mobile_menu .sub_sub_menu>ul.open {
  left: 0;
}

#mobile_menu span.mobile_menu_back {
  background-color: #fafafa;
  text-align: right;
}

#mobile_menu span.mobile_menu_back:hover {
  color: #03a9f4;
}

#mobile_menu span.mobile_menu_back:hover i {
  color: #03a9f4;
}

#mobile_menu a.sale {
  color: #cb2525;
  font-weight: 600;
}

#mobile_menu a.sale i {
  position: static;
  color: #cb2525;
}

#mobile_menu_container.open>div#mobile_menu_container_inner {
  left: 0px;
}


div#mobile_menu_container_inner ul {
  list-style: none;
  padding: 0px !important;
}

li.has_submenu>span>a {
  border: none !important;
  padding: 0px !important;
}

#mobile_menu li.has_submenu>span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
}

#mobile_menu li.has_submenu>span>a {
  border: none !important;
  padding: 15px 20px !important;
  width: 80%;
}

#mobile_menu li.has_submenu>span>i {
  width: 55px;
  height: 50px;
  top: 0px;
  position: relative;
  right: 0px;
  border-left: 2px solid #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.navigation .navbar-expand-lg .dropdown-menu.show {
  display: block;
  width: 80%;
  margin: auto;
  left: 10%;
}

a.nav-link.dropdown-toggle.show::after {
  transform: rotate(225deg) translateX(-3px) translateY(-1px) scale(0.8);
}

hr.or {
  opacity: 1;
  position: relative;
  overflow: visible;
  width: 100%;
}

hr.or:before {
  content: "OR";
  color: black !important;
  opacity: 1;
  position: absolute;
  left: 0px;
  top: -11px;
  left: 47%;
  background: white;
  padding: 0px 10px;
}

.page-item .page-link:focus {
  /* border: none !important; */
  background: white;
  box-shadow: none;
  color: #ff8800;
}


.custom-popup {
  background: #00000038;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.popup-container {
  background: white;
  border-radius: 10px;
  width: 400px;
  max-width: 80%;
  position: relative;
}

.popup-container>button {
  border-radius: 100%;
  background: black;
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 0px !important;
  font-size: 20px;
  margin-top: 10px;
  top: 0px;
  z-index: 1;
  right: 10px;
}

.ratingbox>input:checked+label>i {
  color: gold;
}

.ratingbox>label {
  cursor: pointer;
}

.ratingbox {
  flex-direction: row-reverse;
  justify-content: start;
}

.ratingbox>input#rating2:checked+label+input+label>i,
.ratingbox>input#rating3:checked+label+input+label>i,
.ratingbox>input#rating3:checked+label+input+label+input+label>i,
.ratingbox>input#rating4:checked+label+input+label>i,
.ratingbox>input#rating4:checked+label+input+label+input+label>i,
.ratingbox>input#rating4:checked+label+input+label+input+label+input+label>i,
.ratingbox>input#rating5:checked+label+input+label+input+label+input+label>i,
.ratingbox>input#rating5:checked+label+input+label+input+label>i,
.ratingbox>input#rating5:checked+label+input+label>i,
.ratingbox>input#rating5:checked+label+input+label+input+label+input+label+input+label>i {
  color: gold;
}

.mypromobox {
  background: #42d78c12;
  padding: 10px;
  border: 2px dashed #42d78c;
  border-radius: 10px;
}

@keyframes loading_animation {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

div.loading_icon {
  width: 50px;
  height: 50px;
  border: 3px solid hsl(0deg 0% 91.05%);
  border-top-color: hsl(215deg 27.91% 16.86%);
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  border-radius: 50%;
  animation-name: loading_animation;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

ul.searchfloat {
  padding: 0px 0px;
  position: absolute;
  top: 49px;
  z-index: 9999;
  background: #eff8fe;
  width: 100%;
  left: 0px;
  border-radius: 5px !important;
  border: 2px solid #000000;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  max-height: 300px;
  overflow: auto;
}

/* width */
ul.searchfloat::-webkit-scrollbar {
  width: 5px;

}

/* Track */
ul.searchfloat::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
ul.searchfloat::-webkit-scrollbar-thumb {
  background: #00406e;
}

/* Handle on hover */
ul.searchfloat::-webkit-scrollbar-thumb:hover {
  background: black;
}


ul.searchfloat>li {
  display: block;
  border-bottom: 2px solid #00406e;
  color: #00406e !important;
  text-transform: capitalize;
}

ul.searchfloat>li>a {
  padding: 10px;
  display: block;
  color: #00406e !important;
}

ul.searchfloat>li>a:hover {
  background: #00406e !important;
  color: white !important;
}


.orderpanel .panel {

  max-width: 340px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  border: 0;
  margin: auto;
  background: white;
}

.orderpanel .panel-body {
  padding: 40px;
  font-size: 18px;
  text-align: center;
  color: #666;
}

.orderpanel .panel-heading {
  border: 0;
  font-family: Montserrat;
  text-align: center;
  color: #fff;
  background: linear-gradient(270deg, #ff9327, #0066b3);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 4s ease infinite;
  -moz-animation: AnimationName 4s ease infinite;
  animation: AnimationName 4s ease infinite;
  padding: 27px 0px 1px !important;
}

.orderpanel .panel-heading h2 {
  color: #fff;
  font-weight: 800;
  font-size: 19px;
  margin: 0 0 30px;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.orderpanel .loader-outer {
  background-image: url('https://dl.dropboxusercontent.com/u/128401/live/fontis/binglee/binglee.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 30px auto;
}

.orderpanel .loader,
.orderpanel .loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.orderpanel .loader {
  margin: 40px auto 30px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.4em solid rgba(255, 255, 255, 0.2);
  border-right: 0.5em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.4em solid rgba(255, 255, 255, 0.2);
  border-left: 0.5em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.card-product .product-badges {
  display: none;
}

.card-product .product-buttons {
  display: none;
}

/* Media Queries */
@media(max-width: 1199.5px) {
  nav ul li {
    padding: 0 10px;
  }
}

@media(max-width: 500px) {
  .order .progressbar-track .progressbar li {
    font-size: 1rem;
  }

  .order .progressbar-track .progressbar li::after {
    font-size: 0.8rem;
    top: 35px;
  }

  #tracker {
    top: 20px;
  }
}

@media(max-width: 440px) {
  #main-content {
    padding: 20px;
  }

  .order {
    padding: 20px;
  }

  #step-4::after {
    left: -5px;
  }
}

@media(max-width: 395px) {
  .order .progressbar-track .progressbar li {
    font-size: 0.8rem;
  }

  .order .progressbar-track .progressbar li::after {
    font-size: 0.7rem;
    top: 35px;
  }

  #tracker {
    top: 15px;
  }

  .order .btn.btn-primary {
    font-size: 0.85rem;
  }
}

@media(max-width: 355px) {
  #main-content {
    padding: 15px;
  }

  .order {
    padding: 10px;
  }
}

.variationsmapbutton button.btn {
  border: 1px solid !important;
}

.variationsmapbutton button.btn.active {
  background: #8e8e93;
  color: white;
}

.propop {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  background: white;
  z-index: 99999;
  top: 0px;
  padding: 0px !important;
  margin: 0px;
}

.propop i.ri-close-line {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 30px;
}


@media only screen and (min-width: 900px) {
  .propop {
    display: none;
  }

  .navbar-sticky.navbar-stuck .navbar-actions-item {
    background-color: transparent !important;
    transition: none !important;
  }

  .navbar-sticky.navbar-stuck .navbar-actions-item * {
    color: white;
  }

  .navbar-sticky.navbar-stuck .navbar-actions-item svg {
    fill: white;
  }

  a.nav-link.dropdown-toggle:after {
    content: "";
    display: inline-block !important;
    border: solid #ffffff;
    border-width: 0 2.5px 2.5px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg) translateX(-3px) translateY(-1px) scale(0.8);
  }

  a.nav-link.dropdown-toggle:hover:after {
    border-color: var(--bs-primary);
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1400px;
  }
}



@media only screen and (max-width: 900px) {
  .navbar.navbar-expand-lg.navbar-stuck-menu.bg-body-tertiary {
    transform: translatex(-100%);
    position: fixed;
    left: 0px;
    z-index: 999;
    background: transparent !important;
    width: 90%;
    left: 0px;
    top: 0px;
    height: 100vh;
    align-items: baseline;
    transition: all;
    transition-duration: 0.5s;
  }

  .navbar.navbar-expand-lg.navbar-stuck-menu.bg-body-tertiary:before {
    position: absolute;
    width: 100%;
    background: #00406e;
    left: 0px;
    top: 0px;
    height: 100%;
    z-index: 0;
    content: "";
  }

  .navbar.navbar-expand-lg.navbar-stuck-menu.bg-body-tertiary.show {
    transform: translatex(0%);
  }

  div#navbarCollapse .navbar-nav a {
    color: white !important;
    position: relative;
  }

  div#navbarCollapse .navbar-nav a.nav-link.dropdown-toggle {
    width: 100%;
    justify-content: space-between;
  }

  div#navbarCollapse .nav-item {
    background-color: transparent !important;
  }

  div#navbarCollapse .dropdown-toggle::after {
    border-color: #ffffff;
  }

  div#navbarCollapse .navbar-nav .dropdown-menu.p-0.show {
    background: #4f6ead;
    padding: 17px 0px !important;
  }

  div#navbarCollapse ul.navbar-nav {
    max-height: 90vh;
    overflow: scroll;
  }

  div#navbarCollapse>ul.navbar-nav>li.nav-item {
    border-bottom: 1px solid white !important;
    border-color: white !important;
    position: relative;
    border-radius: 0px;
  }

  div#navbarCollapse>ul.navbar-nav>li.nav-item .widget.widget-links h6 {
    background: #00406e;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
  }

  .hero-swiper .swiper-button-prev:after,
  .hero-swiper .swiper-button-next:after {
    font-size: 10px;
  }

  .hero.hero-swiper .swiper-button-prev,
  .hero.hero-swiper .swiper-button-next {
    width: 20px;
    height: 20px;
  }

  .navbar-sticky.navbar-stuck {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 999;
  }

  .contact_field {
    padding: 40px;
  }

  .contact_info_sec {
    position: relative;
    width: 100%;
    border-radius: 20px;
  }

  .navigation .navbar-actions-item {
    background: transparent !important;
  }

  .navbar-sticky.navbar-stuck .navbar-actions-item *,
  .navbar-sticky.navbar-stuck .navbar-actions-item * {
    color: #ffffff !important;
    fill: white;

  }

  .navbar-sticky .searchbox input {
    width: 100% !important;
  }

  .searchbox {
    align-items: center;
    display: none !important;
    position: absolute;
    width: 95%;
    top: 60px;
    z-index: 99;
  }

  .searchbox:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #00406e;
    position: absolute;
    right: 0px;
    top: -13px;
    z-index: 9999;
    transform: translateX(-50%);
  }

  .searchbox button.btn.search {
    display: flex !important;
  }

  .searchbox.search-view {
    display: flex !important;
  }

  .d-mob-none {
    display: none !important;

  }

  .probox-pad {
    padding: 15px !important;
  }

}